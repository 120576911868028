<template>
  <div>
    <div
      class="top_img"
      :style="{
        'background-image': `url(${imgs[1].img})`,
      }"
    >
      <div class="top_title_div">
        <p class="en">Make it Safer</p>
        <p class="title">
          {{ $i18n.locale == "zh" ? imgs[1].title : imgs[1].titleEn }}
        </p>
      </div>
    </div>
    <div class="info_div">
      <el-row>
        <el-col :xs="24" :sm="24" :md="8">
          <div class="sub_info_div">
            <p class="title">{{ $t("服务热线") }}</p>
            <img src="../../static/img/call_phone_icon.png" />
            <p class="num">{{ contact.tel }}</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8">
          <div class="sub_info_div">
            <p class="title">{{ $t("E-mail") }}</p>
            <img src="../../static/img/call_email_icon.png" />
            <p class="num">{{ contact.email }}</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8">
          <div class="sub_info_div">
            <p class="title">{{ $t("公司地址") }}</p>
            <img src="../../static/img/call_addr_icon.png" />
            <p class="addr">
              {{ $i18n.locale == "zh" ? contact.addr : contact.addren }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="map_div">
      <baidu-map :center="center" :zoom="zoom" @ready="handler" class="bm-view">
        <my-overlay
          :position="{ lng: lng, lat: lat }"
          :text="$i18n.locale == 'zh' ? contact.name : contact.nameen"
          :active="active"
        >
        </my-overlay>
      </baidu-map>
      <div class="map_info_div">
        <p class="title">
          {{ $i18n.locale == "zh" ? contact.name : contact.nameen }}
        </p>
        <div class="item">
          <img src="../../static/img/call_phone_icon.png" />
          <p>{{ $t("座机") }}<br />{{ contact.tel }}</p>
        </div>
        <div class="item border">
          <img src="../../static/img/call_email_icon.png" />
          <p>{{ $t("E-mail") }}<br />{{ contact.email }}</p>
        </div>
        <div class="item">
          <img src="../../static/img/call_addr_icon.png" />
          <p>
            {{ $t("地址") }}<br />{{
              $i18n.locale == "zh" ? contact.addr : contact.addren
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
import MyOverlay from "./view/MyOverlay.vue";
export default {
  components: { MyOverlay },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 13,
      data: {},
      imgs: [{ img: "" }],
      lng: 121.410787,
      lat: 29.857438,
      isMiniWindows: false,
      clientWidth: 0,
      contact: {},
      active: false,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/contact") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    this.getData();
    this.getCallus();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getImg() {
      // var p = { id: 2 };
      API.getImgs()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.page.list;
          }
        })
        .catch(() => {});
    },
    getCallus() {
      API.getCallus()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.contact = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      var that = this;
      this.center.lng = this.lng;
      this.center.lat = this.lat;
      var point = new BMap.Point(this.lng, this.lat);
      if (!this.isMiniWindows) {
        var point = new BMap.Point(this.lng - 0.1, this.lat + 0.02);
      }
      map.enableScrollWheelZoom(true);
      map.centerAndZoom(point, that.zoom);
      map.addEventListener("zoomend", function () {
        // alert("地图缩放至：" + this.getZoom() + "级");
        // console.log("地图缩放至：" + this.getZoom() + "级")
        // var zoom = Math.abs(this.getZoom() - that.zoom)+1;
        // if (this.getZoom() > that.zoom) {
        //   map.panTo(new BMap.Point(that.lng - 0.1/zoom , that.lat));
        // } else {
        //   map.panTo(new BMap.Point(that.lng - 0.1, that.lat + 0.02));
        // }
        if (this.getZoom() == that.zoom) {
          map.panTo(new BMap.Point(that.lng - 0.1, that.lat + 0.02));
        } else {
          map.panTo(new BMap.Point(that.lng, that.lat));
        }
      });
      window.setTimeout(function () {
        map.panTo(point);
      }, 1000);

      // var marker = new BMap.Marker(point); // 创建标注
      // map.addOverlay(marker); // 将标注添加到地图中
    },

    getData() {
      API.getCallus()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.data = res.page.list[0];
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.info_div {
  width: 73%;
  max-width: 140rem;
  padding: 19rem 0 8rem;
  margin: auto;
  .sub_info_div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    img {
      max-width: 2.7rem;
      max-height: 2.6rem;
      object-fit: contain;
      margin: 3rem 0;
    }
    p {
      text-align: left;
    }
    .title {
      font-size: 2.4rem;
      font-family: medium;
      color: $color5E;
    }
    .num {
      font-size: 3rem;
      font-family: Outfit-Medium;
      color: $mainColor;
    }
    .addr {
      font-size: 2.6rem;
      font-family: medium;
      color: $mainColor;
    }
  }
}
.map_div {
  position: relative;
  width: 85.42%;
  max-width: 192rem;
  margin: 0 auto 25rem;
  .map_info_div {
    padding: 8rem 5rem 7rem;
    top: 14.7rem;
    left: 9rem;
    position: absolute;
    background: white;
    .title {
      color: $mainColor;
      font-size: 2.4rem;
      font-family: medium;
      margin-bottom: 3rem;

    }
    .item {
      display: flex;
      padding: 3rem 0;
      box-sizing: border-box;
      &.border {
        border-top: 1px solid $colorE8;
        border-bottom: 1px solid $colorE8;
      }
      img {
        max-width: 2.4rem;
        max-height: 2.4rem;
        object-fit: contain;
      }
      p {
        color: $color5E;
        text-align: left;
        margin-left: 1rem;
        line-height: 1.5;
      }
    }
  }
}
.bm-view {
  width: 100%;
  height: 83rem;
  // /deep/ div {
  //   border-radius: 4rem !important;
  // }
  // height: 400px;
}
@media (max-width: 1200px) {
  .map_div {
    .map_info_div {
      display: none;
    }
  }
}
</style>