<template>
  <div class="swiper_bg" id="Certificates">
    <!-- <div class="home_div title_padding top_margin bottom_margin_5 flex center">
      <p class="home_title">
        {{ $t("产品证书") }}
      </p>
      <div class="page_btn_div">
        <div @click="slideToPrev()" class="prev"></div>
        <div class="next" @click="slideToNext()"></div>
      </div>
    </div> -->
    <div class="middle_banner">
      <!-- <div class="swiper-button-prev swiper-button-prev1">
      </div> -->
      <div class="swiper-container mySwiper mySwiper5" id="mySwiper5">
        <div class="swiper-wrapper">
          <!-- Slides -->
          <!-- <div class="swiper-slide" v-for="(item, index) in List" :key="index"> -->
          <div class="swiper-slide" v-for="(item, index) in zzry" :key="index">
            <div class="home_product_box">
              <img :id="'img' + index" :src="item.img" />
              <div
                class="bottom_div"
                :style="{
                  'background-image': `url(${item.icon})`,
                }"
              >
                <p class="type">
                  {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
                </p>
                <p
                  class="detail_box"
                  @click="navTo('/Products', item.id)"
                  :class="$i18n.locale == 'zh' ? '' : 'en'"
                >
                  {{ $i18n.locale == "zh" ? item.content : item.contentEn }}
                </p>
              </div>
              <p class="bottom_other_type">
                {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
              </p>
            </div>
          </div>
        </div>
        <p class="height_for_phone"></p>
      </div>
      <div class="swiper-pagination"></div>
      <div class="button_div">
        <div class="page_btn_div">
          <div @click="slideToPrev()" class="prev"></div>
          <div class="next" @click="slideToNext()"></div>
        </div>
      </div>
    </div>
    <!-- <p class="swiper_title">
      {{ $i18n.locale == "zh" ? bannertext : bannertextEn }}
    </p> -->
  </div>
</template>
<script>
import * as API from "@/api/index";
import Swiper, { Pagination, Autoplay } from "swiper";
Swiper.use(Pagination);
Swiper.use(Autoplay);
import "swiper/swiper-bundle.css";
export default {
  props: {
    language: {
      type: String,
      default: "",
    },
  },
  watch: {
    "$i18n.locale"(newValue) {
      // console.log("$i18n.locale==", newValue);
      this.initSwiper();
      // if (this.$i18n.locale === "zh") {
      // } else if (this.$i18n.locale === "en") {
      // }
    },
    //监听轮播数据变化后再创建实例
    zzry() {
      this.initSwiper();
    },
  },
  data() {
    return {
      bannertext: "",
      bannertextEn: "",
      zzry: [],
      swiper: Object,
      itemIndex: 0,
      itemIndexNotReal: 0,
    };
  },
  mounted() {
    this.getInfo();
  },
  beforeDestroy() {},
  created() {},
  methods: {
    getInfo() {
      var data = { limit: 1000 };
      API.getBusinessBanner(data)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.zzry = res.page.list;
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      this.$nextTick(() => {
        let that = this;
        this.swiper = new Swiper(".mySwiper5", {
          slidesPerView: 2,
          // spaceBetween: 20,
          // centeredSlides: true,
          loop: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          },
          // navigation: {
          //   nextEl: ".swiper-button-next1",
          //   prevEl: ".swiper-button-prev1",
          // },
          pagination: {
            el: ".swiper-pagination",
          },
          on: {
            activeIndexChange: function () {
              //alert("改变了，activeIndex为" + this.activeIndex);
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.changetext(this.activeIndex, this.realIndex);
            },
          },
          // breakpoints: {
          //   "@0.00": {
          //     slidesPerView: 2,
          //     spaceBetween: 0,
          //   },
          //   "@0.75": {
          //     slidesPerView: 2,
          //     spaceBetween: 15,
          //   },
          //   "@1.00": {
          //     slidesPerView: 4,
          //     spaceBetween: 15,
          //   },
          //   "@1.50": {
          //     slidesPerView: 4,
          //     spaceBetween: 15,
          //   },
          // },
        });
      });
    },
    changetext(active, real) {
      // this.bannertext = this.zzry[i].title;
      // this.bannertextEn = this.zzry[i].titleEn;
      this.itemIndexNotReal = active;
      this.itemIndex = real;
      // console.log("itemIndex==", this.itemIndex);
    },
    goDownload(url) {
      window.open(url, "_blank");
    },
    slideToNext() {
      // var swiper = document.getElementById('mySwiper1');
      this.swiper.slideNext();
      // this.$mySwiper1.slideTo(1);
      // this.swiper.update()
    },
    slideToPrev() {
      this.swiper.slidePrev();
    },
  },
};
</script>
<style lang="scss" scoped>
.page_btn_div {
  display: flex;
  img {
    width: 5rem;
    height: 5rem;
    margin: 0 1rem;
    cursor: pointer;
  }
  .prev {
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../static/img/swiper_left.png");
    &:hover {
      // background-image: url("../../../static/img/swiper_left2.png");
    }
  }
  .next {
    cursor: pointer;
    margin-left: 1rem;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../static/img/swiper_right.png");
    &:hover {
      // background-image: url("../../../static/img/swiper_right2.png");
    }
  }
}
.book1_img {
  transition: 300ms;
  width: 100%;
  // height: 65rem;
  object-fit: cover;
  border-radius: 2rem;
  // transform: scale(0.7);
}

.book1_img:hover {
  // transform: scale(1.0);
}

.swiper_bg {
  // margin: 5rem 0;
  // padding: 3rem 0;
}

.swiper_title {
  margin: 5rem 0 0;
  font-family: demibold;
  font-size: 2.6rem;
  text-align: center;
}

.bannerright {
  width: 8rem;
  cursor: pointer;
  z-index: 99;
  padding: 5rem 3rem;
  border-radius: 6px;
}

.bannerleft {
  width: 8rem;
  cursor: pointer;
  z-index: 99;
  padding: 5rem 3rem;
  border-radius: 6px;
}

.bannerleft:hover,
.bannerright:hover {
  background: rgba(0, 0, 0, 0.05);
}
.button_div {
  position: absolute;
  bottom: 7.7rem;
  left: 40rem;
  z-index: 1000;
}
.swiper-button-prev {
  // background-image: url('../../../static/img/资源45.png');
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 10rem;
  height: 10rem;
  // margin-left: 10rem;
  outline: none;
}

.swiper-button-prev:hover {
  // background-image: url('../../static/img/bannerlefthover.png');
}

.swiper-button-next {
  // background-image: url('../../../static/img/资源452.png');
  color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 10rem;
  height: 10rem;
  // margin-right: 10rem;
  outline: none;
}

.swiper-button-next:hover {
  // background-image: url('../../static/img/bannerrighthover.png');
}

.mySwiper {
  width: 100%;
  margin-bottom: 10rem;
  margin-top: 5rem;
  height: 68rem;
  // margin: 3rem 8rem;
}
.swiper-wrapper {
  height: 63rem;
}
.swiper-pagination {
  // display: none;
  left: 25rem;
  bottom: 10rem;
  width: auto;
  /deep/ .swiper-pagination-bullet {
    width: 1.8rem;
    height: 2px;
    border-radius: unset;
    margin: 0 5px;
  }
  /deep/ .swiper-pagination-bullet-active {
    background: $textRedColor;
  }
}

.middle_banner {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  position: relative;
}

.swiper-slide {
  height: 94%;
  text-align: center;
  font-size: 1.8rem;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: start;
  transition: 300ms;
  // border-radius: 2rem;
  // background: white;
  // padding: 1rem;
  box-sizing: border-box;
  // margin:2rem 0;
  // transform: scale(0.9);
  // box-shadow: 1rem 1rem 2rem 1px #00000029;
  .home_product_box {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
      width: 50%;
      transform: none;
      border-radius: unset;
      object-position: right;
      object-fit: cover;
    }
    .type {
      color: white;
      text-align: start;
      font-family: medium;
      font-size: 3.2rem;
    }
    .detail_box {
      margin-top: 1rem;
      text-align: justify;
      color: white;
      font-size: 2rem;
      line-height: 3.4rem;
      // border: #1961ac solid 1px !important;
      // background: #1961ac;
      &.en {
        line-height: 2.4rem;
        text-align: start;
      }
    }
  }
  .bottom_div {
    display: none;
    width: 35%;
    padding: 3rem 4rem 4rem;
    background: $textRedColor;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
  }
  .bottom_other_type {
    display: block;
    text-align: start;
    padding: 2rem;
    width: fit-content;
    color: $textRedColor;
  }
}
.cover {
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.5s;
  background: $color01162D-30;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  .content {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    margin: auto;
    font-family: regular;
    font-size: 1.8rem;
    border: $textBlueColor solid 1px;
    background-color: $textBlueColor;
    color: white;
    border-radius: 5rem;
    padding: 1rem 4rem;
    width: fit-content;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: 1rem;
      width: 1.3rem;
      height: 1.2rem;
      background: url("../../../static/img/download_icon.png");
      background-size: cover;
      background-origin: border-box;
      display: inline-block;
    }
  }
}
.swiper-slide-active {
  height: 100%;
  width: 75% !important;
  .home_product_box {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
      width: 100%;
      transform: none;
      border-radius: unset;
      object-position: center;
      margin-left: 1rem;
      width: calc(100% - 1rem);
      object-fit: cover;
    }
    .type {
      color: white;
      text-align: start;
      font-family: medium;
      font-size: 3.2rem;
    }
    .detail_box {
      margin-top: 1rem;
      text-align: justify;
      color: white;
      font-size: 2rem;
      line-height: 3.4rem;
      // border: #1961ac solid 1px !important;
      // background: #1961ac;
    }
  }
  .bottom_div {
    display: block;
    width: 35%;
    padding: 3rem 4rem 4rem;
    background: $textRedColor;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-origin: content-box;
  }
  .bottom_other_type {
    display: none;
    text-align: start;
    padding: 2rem;
    width: fit-content;
    color: $textRedColor;
  }
}
.swiper-slide-prev {
  opacity: 0;
}
// .swiper-slide:hover {
//   transform: scale(0.9);
// }

// .swiper-slide-active, .swiper-slide-duplicate-active {
// transform: scale(1);
// }

@media screen and (max-width: 1200px) {
}
</style>
