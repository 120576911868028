<template>
  <div class="footer-father">
    <div class="footer-top">
      <!-- <img src="../../../static/img/logo2.png" /> -->
    </div>
    <div class="nav_footer">
      <div class="footer">
        <div v-for="(item, i) in nav_title_list" :key="i" class="footer-sub">
          <div>
            <p v-if="i == 0 || i == 2" class="title" @click="navto(item.path)">
              {{ i == 0 ? $t("首页导航") : item.title }}
            </p>
            <p
              class="content"
              v-for="sub in item.children"
              :key="sub.title"
              @click="scrollNavto(i, sub.path, sub.tag)"
            >
              {{ sub.title }}
            </p>
            <p
              v-if="i == 2"
              class="content"
              v-for="sub in product_list"
              :key="sub.title"
              @click="scrollNavto(i, item.path, sub.id)"
            >
              {{ $i18n.locale == "zh" ? sub.name : sub.nameEn }}
            </p>
          </div>
          <!-- <div class="line" v-show="i<list.length-1"></div> -->
        </div>
      </div>

      <div class="qrcode-div">
        <p>{{ $t('联系我们') }}</p>
        <p class="tel p_top">{{ member.tel }}</p>
        <p class="tel">{{ member.email }}</p>
        <img
            class="qrcode"
            :src="imgs[0].img"
          />
        <!-- <div>
          <img
            class="qrcode"
            :src="
              activeQrcode == 0
                ? contact.pic1
                : activeQrcode == 1
                ? contact.pic2
                : contact.pic3
            "
          />
          <div class="qrcode_icon_div">
            <img
              v-if="contact.pic1"
              src="../../../static/img/footer_ali_icon.png"
              @mouseenter="qrcode(0)"
            />
            <img
              v-if="contact.pic2"
              src="../../../static/img/footer_wechat_icon.png"
              @mouseenter="qrcode(1)"
            />
            <img
              v-if="contact.pic3"
              src="../../../static/img/footer_qq_icon.png"
              @mouseenter="qrcode(2)"
            />
          </div>
        </div> -->
      </div>
    </div>
    <div class="footer-bottom">
      <!-- <div class="line2"></div> -->
      <img :src="require('../../../static/img/logo.png')" />
      <p class="content" style="display: flex">
        {{ $t("版权所有") }}
        <a href="https://beian.miit.gov.cn/">浙ICP备17030255号</a>
      </p>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/index";
import * as navBar from "./navbar";
export default {
  data() {
    return {
      contact: {},
      product_list: [],
      member: {},
      activeQrcode: 0,
      imgs: [{ img: "" }],
    };
  },
  computed: {
    nav_title_list() {
      return navBar.getList();
    },
  },
  components: {},
  mounted() {
    this.Init();
  },

  methods: {
    Init() {
      // this.getData();
      this.getCallus();
      this.getTypeList();
      this.getImg();
    },
    getImg() {
      var p = { title: "二维码" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.page.list;
          }
        })
        .catch(() => {});
    },
    getCallus() {
      API.getCallus()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.member = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    getData() {
      API.getContacts()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.contact = res.data[0];
          }
        })
        .catch(() => {});
    },
    getTypeList() {
      API.getTypes()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.product_list = res.page.list;
            // this.nav_title_list[2].children = this.product_list;
          }
        })
        .catch(() => {});
    },
    qrcode(i) {
      this.activeQrcode = i;
    },
    navto(path) {
      this.$router.push(path);
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
      this.jumpTo(command);
    },
    scrollNavto(index, path, name) {
      if (!name) {
        this.navto(path);
      } else {
        this.$router.push({
          path: `${path}`,
          query: { param: name },
        });
      }
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped></style>
