<template>
  <div>
    <div
      class="top_img"
      :style="{
        'background-image': `url(${imgs[3].img})`,
      }"
    >
      <div class="top_title_div">
        <p class="en">Make it Safer</p>
        <p class="title">
          {{ $i18n.locale == "zh" ? imgs[3].title : imgs[3].titleEn }}
        </p>
      </div>
    </div>

    <div class="bg_div">
      <div class="menu_div">
        <div>
          <div
            class="menu"
            :class="activeIndex == i ? 'active' : ''"
            v-for="(item, i) in types"
            :key="i"
            @click="selectType(i)"
          >
            <p>{{ $i18n.locale == "zh" ? item.name : item.nameEn }}</p>
          </div>
        </div>
        <div class="products_div">
          <el-row :gutter="30">
            <el-col :md="12" :sm="24" v-for="(item, i) in data" :key="i">
              <div
                class="cases_item_div"
                @click="navTo('/productsdetail/id=' + item.id)"
              >
                <img :src="item.cover" />
                <div class="item_div">
                  <div>
                    <!-- <p class="p1">Make it Safer</p> -->
                    <p class="p2">{{ item.nameEn }}</p>
                  </div>
                  <p class="p3" :class="item.name ? '' : 'right'" v-html="item.name.replace('<br>',' ')">
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  components: {},
  data() {
    return {
      data: {},
      types: {},
      imgs: [{ img: "" }],
      isMiniWindows: false,
      clientWidth: 0,
      activeIndex: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/products") != -1) {
        window.scrollTo(0, 0);
        var param = this.$route.query.param;
        if (param) {
          console.log("news param:" + param);
          this.activeIndex = param;
        }else{
          this.activeIndex = 0;

        }
        this.getData();
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    var param = this.$route.query.param;
    if (param) {
      console.log("news param:" + param);
      this.activeIndex = param;
    }
    this.getData();
    this.getTypeList();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getImg() {
      // var p = { title: "产品中心" };
      API.getImgs()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.page.list;
          }
        })
        .catch(() => {});
    },
    selectType(index) {
      this.activeIndex = index;
      this.getData();
    },
    getData() {
      var p = {};
      if (this.activeIndex != 0) {
        var p = { type: this.activeIndex };
      }
      API.getProducts(p)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.data = res.page.list;
          }
        })
        .catch(() => {});
    },
    getTypeList() {
      API.getTypes()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            var top = {};
            top.name = "所有产品";
            top.nameEn = "All";
            top.id = 0;
            this.types = res.page.list;
            this.types.unshift(top);
            // this.nav_title_list[2].children = this.product_list;
          }
        })
        .catch(() => {});
    },
    navTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg_div {
  background: $colorF5;
  padding: 13rem 0;
}
.menu_div {
  width: 85.42%;
  max-width: 192rem;
  margin: auto;
  display: flex;
}
.menu {
  width: 38.2rem;
  padding: 3.3rem 0 2.4rem;
  cursor: pointer;
  &.active {
    p {
      font-family: bold;
      color: $mainColor;
      &::after {
        content: "";
        width: 2.6rem;
        height: 2.6rem;
        background: url("~@/../static/img/arrow_green_right2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto 1rem auto 2.8rem;
      }
    }
  }
  &:not(:last-child) {
    border-bottom: 0.5px solid $colorBBB;
  }
  p {
    font-size: 2.2rem;
    color: $color888;
    font-family: light;
    text-align: start;
    display: flex;
    justify-content: space-between;
    &::after {
      content: "";
      width: 2.6rem;
      height: 2.6rem;
      background: url("~@/../static/img/product_types_arrow_icon.png");
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
      margin: auto 1rem auto 2.8rem;
    }
  }
}
.products_div {
  margin-left: 7rem;
  width: 100%;
}
.cases_item_div {
  cursor: pointer;
  background: white;
  margin-bottom: 14rem;
  height: 53.56rem;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    background: linear-gradient(
      135deg,
      white,
      #00c8b7ff,
      $mainColor,
      $mainColor
    );
    left: 0;
    top: 0;
    // transition: 0.5s;
    border-bottom-right-radius: 100%;
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .item_div {
    transition: all 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 4rem 3.8rem 2.7rem;
  }
  .p1 {
    margin-top: 6rem;
    text-align: start;
    color: $mainColor;
    font-size: 4rem;
    // line-height: 2.6rem;
    font-family: Outfit-Regular;
    transition: 0.5s;
  }
  .p2 {
    text-align: right;
    color: $colorBBB;
    font-family: Outfit-Regular;
    // transition: all 0.5s;
    font-size: 2.1rem;
    transition: 0.5s;
  }
  .p3 {
    transition: 0.5s;
    text-align: start;
    color: $colorBBB;
    // transition: all 0.5s;
    font-size: 2.9rem;
    font-family: medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.right {
      justify-content: right;
    }
    &::after {
      transition: 0.5s;
      content: "";
      width: 3rem;
      height: 3rem;
      background: url("~@/../static/img/arrow_green_gray_right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &:hover {
    &::before {
      content: "";
      height: calc(100% * 1.42);
      width: calc(100% * 1.42);
      position: absolute;
      background: linear-gradient(
        135deg,
        white,
        #00c8b7ff,
        $mainColor,
        $mainColor
      );
      left: 0;
      top: 0;
      transition: 0.5s;
    }
    p {
      color: white;
      transition: 0.5s;
    }
    .p3 {
      &::after {
        transition: 0.5s;
        content: "";
        width: 3rem;
        height: 3rem;
        background: url("~@/../static/img/arrow_green_w_right.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
@media (max-width: 1200px) {
  .menu_div {
    flex-direction: column;
  }
  .products_div {
    margin-left: 0;
  }
  .cases_item_div {
    margin-bottom: 4rem;
  }
}
</style>