<template>
  <div>
    <home-banner class="top_banner" />
    <home-product-banner></home-product-banner>
    <div class="company_div">
      <div class="left" :style="{ backgroundImage: `url(${imgs[0].img})` }">
        <p class="title">
          {{ $i18n.locale == "zh" ? company.title : company.titleEn }}
        </p>
        <p
          class="content"
          v-html="$i18n.locale == 'zh' ? company.content : company.contentEn"
        ></p>
        <p class="more_gray" @click="navTo('about')">{{ $t("了解更多") }}</p>
      </div>
      <img :src="company.img" />
    </div>
    <div class="safe_div">
      <p class="title1">Make it Safer</p>
      <p class="title2">{{ $t("莱富安全第一") }}</p>
      <safe-view></safe-view>
    </div>
    <div
      v-for="(item, index) in homecontent"
      :key="index"
      class="home_content_div"
    >
      <img :src="item.img" />
      <div class="content_div" :class="index % 2 != 0 ? 'right' : ''">
        <div class="icon"></div>
        <p class="p1">Make it Safer</p>
        <p class="p2">{{ $i18n.locale == "zh" ? item.title : item.titleEn }}</p>
        <p class="p3" v-html=" $i18n.locale == 'zh' ? item.content : item.contentEn ">
          
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import * as API from "@/api/index";
import HomeBanner from "./view/HomeBanner.vue";
import HomeProductBanner from "./view/HomeProductBanner.vue";
import ArcBg from "./view/ArcBg.vue";
import AccordionView from "./view/AccordionView.vue";
import AboutSwiper2 from "./view/AboutSwiper2.vue";
import AboutSwiper5 from "./view/AboutSwiper5.vue";
import SafeView from "./view/SafeView.vue";
export default {
  data() {
    return {
      oldScrollTop: 0,
      numScrollStart: false,
      company: "",
      news: [],
      homecontent: [{ img: "" }],
      homemsg: [{ title: "", titleEn: "", midTitle: "", midTitleEn: "" }],
      imgs: [{ img: "" }],
      types: [{}, {}, {}, {}, {}, {}, {}],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  components: {
    HomeBanner,
    HomeProductBanner,
    ArcBg,
    AccordionView,
    AboutSwiper2,
    countTo,
    AboutSwiper5,
    SafeView,
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path == "/") {
        window.scrollTo(0, 0);
      }
    },
  },
  mounted() {
    this.Init();
    window.scrollTo(0, 0);
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },

  methods: {
    navTo(path, param) {
      if (param) {
        this.$router.push({
          path: `${path}`,
          query: { param: param },
        });
      } else {
        this.$router.push(path);
      }
    },
    Init() {
      // this.getHomemsg();
      this.getAbout();
      // this.getNews();
      this.getImg();
      this.getHomeContent();
      // 监听页面滚动事件
      window.addEventListener("scroll", this.scrolling);
      const ele = document.querySelector("#home_about_box");
      console.log("ele==", ele);
    },
    getHomemsg() {
      API.getHomemsg()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.homemsg = res.page.list;
          }
        })
        .catch(() => {});
    },
    getHomeContent() {
      API.getHomeContent()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.homecontent = res.page.list;
          }
        })
        .catch(() => {});
    },
    getAbout() {
      API.getHomeAbout()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.company = res.page.list[0];
          }
        })
        .catch(() => {});
    },
    getImg() {
      // var p = { title: "关于莱富" };
      API.getImgs()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.page.list;
            console.log("imgs==", this.imgs);
          }
        })
        .catch(() => {});
    },
    getNews() {
      var data = {
        page: 1,
        limit: 4,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.news = res.page.list;
          }
        })
        .catch(() => {});
    },
    //判断介绍部分是否出现，开始数字动画
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      var clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth < 1600) {
        if (this.oldScrollTop > 600) {
          this.handleScroll();
        }
      } else if (clientWidth < 1700) {
        if (this.oldScrollTop > 1200) {
          this.handleScroll();
        }
      } else {
        if (this.oldScrollTop > 1800) {
          this.handleScroll();
        }
      }
      var home_content_div_list =
        document.getElementsByClassName("home_content_div");
      for (var i = 0; i < home_content_div_list.length; i++) {
        const distanceToTop = home_content_div_list[i].offsetTop;
        // console.log(i+'==='+distanceToTop);
        if ((distanceToTop-this.oldScrollTop) <= window.innerHeight / 2) {
          home_content_div_list[i].classList.add("active");
        }
      }
    },
    handleScroll() {
      if (!this.numScrollStart) {
        // this.$refs.mynum1.start();
        // this.$refs.mynum2.start();
        // this.$refs.mynum3.start();
      }
      this.numScrollStart = true;
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.types = res.data;
          }
        })
        .catch(() => {});
    },
  },
  created() {
    // window.addEventListener("onmessageWS", this.addAnimatedIn);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.company_div {
  display: flex;
  height: 100vh;
  max-height: 108rem;
  .left {
    // width: 50%;
    padding-left: 14rem;
    padding-right: 9.4rem;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    p {
      text-align: start;
    }
    .title {
      font-size: 5rem;
      color: $mainColor;
      margin: 20rem 0 8rem;
    }
    .content {
      margin-bottom: 7rem;
      line-height: 3.4rem;
    }
  }
  img {
    width: 52%;
    object-fit: cover;
  }
}
.safe_div {
  .title1 {
    font-size: 6.5rem;
    color: $mainColor;
    font-family: Outfit-Light;
    margin-top: 13.5rem;
  }
  .title2 {
    margin-top: 1rem;
    margin-bottom: 5rem;
    font-size: 5rem;
    color: $mainColor;
  }
}
.home_content_div {
  position: relative;
  margin-bottom: 19rem;
  img {
    width: 100%;
    height: 65rem;
    object-fit: cover;
  }
  .content_div {
    min-height: 64.4rem;
    position: absolute;
    left: 9.2rem;
    top: 6.5rem;
    background: $textWhiteColor80;
    padding: 5.4rem 7.4rem 0 4.8rem;
    width: 54rem;
    box-sizing: border-box;
    overflow: hidden;
    &::before {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      background: linear-gradient(
        135deg,
        white,
        #00c8b7ff,
        $mainColor,
        $mainColor
      );
      left: 0;
      top: 0;
      // transition: 0.5s;
      border-bottom-right-radius: 100%;
    }
    &.right {
      right: 9.2rem;
      left: unset;
    }

    p {
      text-align: left;
    }
    .p1 {
      margin-top: 9.5rem;
      font-size: 6rem;
      font-family: Outfit-Light;
      color: $mainColor;
      // line-height: 4.2rem;
      opacity: 0.2;
      position: relative;
      transition: 0.5s;
    }
    .p2 {
      font-size: 5rem;
      color: $mainColor;
      position: relative;
      transition: 0.5s;
      // line-height: 3.5rem;
    }
    .p3 {
      margin-top: 9.8rem;
      margin-bottom: 15.7rem;
      font-size: 2.2rem;
      color: $mainColor;
      line-height: 3.4rem;
      position: relative;
      transition: 0.5s;
    }

    .icon {
      transition: 0.5s;
      position: relative;
      width: 3.5rem;
      height: 3.5rem;
      background: url("../../static/img/content_icon_green.png");
    }

    // &:hover {
    //   &::before {
    //     content: "";
    //     height: calc(100% * 1.42);
    //     width: calc(100% * 1.42);
    //     position: absolute;
    //     background: linear-gradient(
    //       135deg,
    //       white,
    //       #00c8b7ff,
    //       $mainColor,
    //       $mainColor
    //     );
    //     left: 0;
    //     top: 0;
    //     transition: 0.5s;
    //     border-bottom-right-radius: 100%;
    //   }
    //   p {
    //     transition: 0.5s;
    //     color: white;
    //   }
    //   .icon {
    //     transition: 0.5s;
    //     background: url("../../static/img/content_icon.png");
    //   }
    // }
  }
  &.active {
    .content_div {
      &::before {
        content: "";
        height: calc(100% * 1.42);
        width: calc(100% * 1.42);
        position: absolute;
        background: linear-gradient(
          135deg,
          white,
          #00c8b7ff,
          $mainColor,
          $mainColor
        );
        left: 0;
        top: 0;
        transition: 0.5s;
        border-bottom-right-radius: 100%;
      }
      p {
        transition: 0.5s;
        color: white;
      }
      .icon {
        transition: 0.5s;
        background: url("../../static/img/content_icon.png");
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .company_div {
    flex-direction: column;
    height: auto;
    .left {
      padding: 4rem;
      .title {
        margin: 2rem 0;
      }
    }
    img {
      width: 100%;
    }
  }
  .home_content_div {
    .content_div {
      left: 0;
      &.right {
        right: 0;
        left: 0;
      }
    }
  }
}
</style>
