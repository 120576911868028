import request from '@/axios/request'

export function getAbout(params) {
    return request({
        url: '/about/list',
        method: 'get',
        params: params
    })
}

export function createMsg (data) {
    return request({
        url: '/msg/createMsg',
        method: 'post',
        data: data
    })
}
export function getHomeBanner(params){
    return request({
        url: '/homebanner/list',
        method: 'get',
        params: params
    })
}
export function getProducts(params){
    return request({
        url: '/products/list',
        method: 'get',
        params: params
    })
}
export function getCallus(params){
    return request({
        url: '/contact/list',
        method: 'get',
        params: params
    })
}

export function getHomeAbout(params){
    return request({
        url: '/homeabout/list',
        method: 'get',
        params: params
    })
}

export function getHomeSafe(params){
    return request({
        url: '/homesafe/list',
        method: 'get',
        params: params
    })
}
export function getHomeContent(id){
    return request({
        url: `/homecontent/list`,
        method: 'get',
    })
}
export function getTypes(params){
    return request({
        url: '/types/list',
        method: 'get',
        params: params
    })
}
export function getHonor(params){
    return request({
        url: '/honor/list',
        method: 'get',
        params: params
    })
}
export function getHomemsg(params){
    return request({
        url: '/homemsg/list',
        method: 'get',
        params: params
    })
}
export function getInvestment(params){
    return request({
        url: '/investment/list',
        method: 'get',
        params: params
    })
}
export function getProductsDetail(id){
    return request({
        url: `/products/info/${id}`,
        method: 'get',
    })
}
export function getImgs(params){
    return request({
        url: '/imgs/list',
        method: 'get',
        params: params
    })
}
export function getNews(params){
    return request({
        url: '/news/list',
        method: 'get',
        params: params
    })
}
export function getNewsDetail(id){
    return request({
        url: `/news/info/${id}`,
        method: 'get',
    })
}
//成员公司
export function getMember(){
    return request({
        url: '/member/list',
        method: 'get',
    })
}
//集团概况
export function getOverview(){
    return request({
        url: '/overview/list',
        method: 'get',
    })
}
//置业板块
export function getProperty(params){
    return request({
        url: '/property/list',
        method: 'get',
        params: params
    })
}