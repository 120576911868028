import axios from "axios";

// const BASE_URL = "http://120.55.66.245/demo/app";
// const BASE_URL = "http://120.55.73.87:8888/demo/app";
const BASE_URL = "http://lafomed.cn/demo/app";
// const BASE_URL="http://localhost:8083/demo/app";
// 2. 配置信息
let config = {
  // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
  baseURL: BASE_URL,
  // 请求超时时间
  timeout: 10000,
  // 每次请求携带cookie
  withCredentials: true,
}

// 创建axios实例
const service = axios.create(config);
// request拦截器
service.interceptors.request.use(
  config => {
    // console.log(config)
    config.headers["Content-Type"] = "application/json;charset=utf-8";

    // config.headers['token'] = getToken()
    // config.responseType = 'blob'
    // if (config.method.toLowerCase() === "post") {
    //   config.data = {
    //     data: Encrypt(JSON.stringify(config.data), 'api')
    //   };
    // }
    return config;
  },
  error => {
    // console.log(error);
    Promise.reject(error);
  }
);
// response拦截器
service.interceptors.response.use(
  response => {
    const { data } = response
    console.log(response.config.url,data);
    const code = data.code
    if (code === 0) {
      return data
    } else {
      Notification.error({
        title: data.msg,
        duration: 2500
      })
      return Promise.reject('error')
    }
  },
  error => {
    let code = 0;
    try {
      code = error.response.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        Notification.error({
          title: "网络请求超时",
          duration: 2500
        });
        return Promise.reject(error);
      }
      if (error.toString().indexOf("Error: Network Error") !== -1) {
        Notification.error({
          title: "网络请求错误",
          duration: 2500
        });
        return Promise.reject(error);
      }
    }
    if (code === 404) {
      Notification.error({
        title: "服务器异常" + code,
        duration: 2500
      });
      return Promise.reject(error);
    } else {
      const errorMsg = error.response.data.message
      if (errorMsg !== undefined) {
        Notification.error({
          title: errorMsg,
          duration: 1000
        })
      }
    }
    return Promise.reject(error);
  }
);

export default service;
