<!-- 弧形背景 -->
<template>
  <div class="header">
    <div class="bg"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      List: [],
    };
  },
  created() {},
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
.header {
  position: relative;
  margin: 0 auto;
  height: 6rem;
  overflow: hidden;
  top: -6rem;
  /* max-width: 990px; */
}

.bg {
  position: relative;
  background: transparent;
  height: 40rem;
}

.bg::before {
  display: block;
  content: '';
  position: absolute;
  // width: 160%;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #fff, #1961ac);
  /* left: -30%; */
  border-radius: 5rem 5rem 0 0;
}

.bg::after {
  content: '';
  position: absolute;
  // width: 160%;
  width: 100%;
  height: calc(100% - 15px);
  background: #fff;
  bottom: 0;
  /* left: -30%; */
  display: block;
  border-radius: 5rem 5rem 0 0;
}

@media screen and (max-width: 1200px) {
  .header {
    height: 4rem;
    top: -4rem;
  }

  .bg {
    height: 5rem;
  }

  .bg::before {
    border-radius: 3rem 3rem 0 0;
  }

  .bg::after {
    height: calc(100% - 7px);
    border-radius: 3rem 3rem 0 0;
  }
}
</style>