<template>
  <el-carousel
    class="outside_div"
    :autoplay="true"
    interval="4000"
    trigger="click"
    arrow="never"
    height="100%"
    ref="carousel"
  >
    <el-carousel-item v-for="(item, index) in List" :key="index">
      <v-touch
        :swipe-options="{ direction: 'horizontal' }"
        v-on:swipeleft="swiperright(index)"
        v-on:swiperight="swiperleft(index)"
        class="wrapper"
      >
        <div class="home_banner_div" @click="jumpTo(item.url)">
          <img :src=" item.img" />
          <div class="home_banner_txt_div">
            <p class="title_en">Make it Safer</p>
            <p
              class="title_2"
              :class="item.price ? 'medium' : ''"
              v-html="$i18n.locale == 'zh' ? item.title : item.titleEn"
            ></p>
            <p
              class="title_1"
              v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
            ></p>
            <p v-if="item.price" class="title_1" v-html="item.price"></p>
            <p v-if="item.price" class="more_green" @click="jumpTo(item.url)">
              {{ $t("了解更多") }}
            </p>
          </div>
        </div>
      </v-touch>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      List: [],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  created() {},
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getBannerList();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  destroyed() {},
  methods: {
    getBannerList() {
      API.getHomeBanner()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.List = res.page.list;
          }
        })
        .catch(() => {});
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      this.$refs.carousel.prev();
      //设置幻灯片的索引
      this.$refs.carousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      this.$refs.carousel.next();
      this.$refs.carousel.setActiveItem(index + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
}

.outside_div {
  height: 87rem;
  width: 100%;
  z-index: 0;

  /deep/.el-carousel__indicators--horizontal {
    bottom: 4rem;
    padding: 12px 20px;
    width: max-content;
  }

  /deep/.el-carousel__button {
    width: 5rem;
    height: 0.6rem;
    margin: 0 1.5rem;
    opacity: 1;
    background-color: $textWhiteColor50;
    position: relative;
    overflow: hidden;

    &::after {
      // animation: mymoveback 4s linear 1 forwards ;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $mainColor;
      position: absolute;
      top:0;
      left:-100%;
    }
  }

  /deep/.el-carousel__indicator.is-active button {
    background-color: $textWhiteColor50;
    &::after {
      animation: mymove 4s linear 1 ;
      content: "";
      width: 100%;
      height: 100%;
      background-color: $mainColor;
      position: absolute;
    }

    // width: 22.5rem;
  }
}

@keyframes mymoveback {
  from {
    left: 0;
    top: 0;
  }
  to {
    left: 100%;
    top: 0;
  }
}

@keyframes mymove {
  from {
    left: -100%;
    top: 0;
  }
  to {
    left: 0;
    top: 0;
  }
}

/deep/ .el-carousel__arrow {
  &:hover {
    background-color: transparent;
  }
}
/deep/ .el-carousel__arrow--left {
  background-image: url("../../../static/img/banner_left_icon.png");
  background-size: contain;
  height: 6.5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 3rem;
  border-radius: unset;
  left: 15rem;
  i {
    display: none;
  }
}
/deep/ .el-carousel__arrow--right {
  background-image: url("../../../static/img/banner_right_icon.png");
  background-size: contain;
  height: 6.5rem;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 3rem;
  border-radius: unset;
  right: 15rem;
  i {
    display: none;
  }
}

.home_banner_div {
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .home_banner_txt_div {
    margin: 0 auto;
    max-width: 1920px;
    width: 85.42%;
    position: absolute;
    left: 0;
    right: 0;
    top: 33rem;

    .title_1 {
      color: $mainColor;
      letter-spacing: 1px;
      text-align: left;
      font-size: 2.2rem;
      line-height: 4rem;
      font-family: light;
      margin-bottom: 4rem;
    }

    .title_2 {
      color: $mainColor;
      text-align: left;
      font-size: 6rem;
      line-height: 8rem;
      font-family: regular;
      // margin-top: 2.6rem;
      margin-bottom: 2rem;
      &.medium {
        font-family: medium;
        margin-bottom: 7rem;
      }
    }
    .title_en {
      color: $mainColor;
      text-align: left;
      font-size: 6rem;
      line-height: 3.5rem;
      font-family: Outfit-Light;
      // margin-top: 2.6rem;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home_banner_div {
    // img {
    // object-fit: contain;
    // }
    .home_banner_txt_div {
      // left: 5rem;
      width: 90%;
      top: 40%;

      .title_1 {
        // color: white;
        // line-height: 1.5;
        font-size: 1.5rem;
      }

      .title_2 {
        // color: white;
        font-size: 3rem;
        line-height: 30px;
      }
    }

    .more_touch_box_b_3E {
      font-size: 2.2rem;
      border: #1961ac solid 1px !important;
      border-radius: 5rem;
      padding: 1rem 4rem;
      width: fit-content;
      color: white;
      cursor: pointer;
      transition: all 0.5s;
      background-color: #1961ac !important;
    }
  }

  .outside_div {
    height: 60rem !important;

    /deep/.el-carousel__indicators--horizontal {
      bottom: 0;
    }
    /deep/.el-carousel__button {
      width: 5rem;
      height: 0.6rem;
      margin: 0 1rem;
      // opacity: 1;
      // background-color: white;
    }

    /deep/.el-carousel__indicator.is-active button {
      // background-color: #1961ac;
      // width: 12.5rem;
    }
  }
  /deep/ .el-carousel__arrow {
    display: none;
  }
}
</style>