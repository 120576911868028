<!-- 手风琴图片效果 -->
<template>
  <div class="box-div">
    <div
      class="box"
      :class="{
        eleactive: eleindex == i,
        hide:
          (eleindex != -1 && eleindex < 3 && i > 4) ||
          (eleindex > 3 && i < 2) ||
          (eleindex == 3 && (i == 0 || i == 6)),
      }"
      v-for="(ele, i) in piclist"
      :key="i"
      @mouseenter="enter(i)"
      @mouseleave="out(i)"
    >
      <img :src="ele.img" />
      <div v-if="eleindex != -1 && eleindex != i" class="top-gradient"></div>
      <div v-if="eleindex == -1 || eleindex == i" class="bottom-gradient"></div>
      <div class="top">
        <p class="title">
          {{ $i18n.locale == "zh" ? ele.title : ele.titleEn }}
        </p>
        <p
          class="content"
          v-html="$i18n.locale == 'zh' ? ele.smallTitle : ele.smallTitleEn"
        ></p>
      </div>
      <div class="bottom">
        <!-- <img :src="bottomIcon[i]"/> -->
        <p
          v-html="$i18n.locale == 'zh' ? ele.bottomTitle : ele.bottomTitleEn"
        ></p>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      eleindex: -1,
      piclist: [
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
        {
          title: "test",
          simple: "test",
          img1: require("../../../static/img/core-bg2-img1.png"),
        },
      ],
      // bottomTitle:[
      //   '注塑',
      //   '吹塑',
      //   '激光切割',
      //   '松下专用<br>弧焊机器人',
      //   'CNC数控机床',
      //   '装配',
      //   '检验',
      // ],
      // bottomIcon:[
      //   require('../../static/img/home-page-4-icon1.png'),
      //   require('../../static/img/home-page-4-icon2.png'),
      //   require('../../static/img/home-page-4-icon3.png'),
      //   require('../../static/img/home-page-4-icon4.png'),
      //   require('../../static/img/home-page-4-icon5.png'),
      // ]
    };
  },
  created() {},
  watch: {
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      var data = { limit: 5, type: 1 };
      API.getIndustry()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.piclist = res.page.list;
          }
        })
        .catch(() => {});
    },
    enter(i) {
      console.log(i);
      this.eleindex = i;
    },
    out(i) {
      this.eleindex = -1;
    },
    navto(path) {
      if (this.isWechat()) {
        this.$router.replace(path);
      } else {
        this.$router.push(path);
      }
    },
    isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },
  },
  destroyed() {},
};
</script>
<style lang="stylus" scoped></style>