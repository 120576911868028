<template>
  <div class="safe_sub_div">
    <!-- <div class="p_div">
      <div
        v-for="(item, index1) in $i18n.locale == 'zh'
          ? List.title1
          : List.title1En"
        :key="index1"
        class="safe_p_div"
      >
        <p class="dot"></p>
        <p>{{ item }}</p>
      </div>
    </div> -->
    <div class="bottom_div">
      <!-- <div>
        <div
          v-for="(item, index2) in $i18n.locale == 'zh'
            ? List.title2
            : List.title2En"
          :key="index2"
          class="safe_p_div reverse"
        >
          <p class="dot"></p>
          <p>{{ item }}</p>
        </div>
      </div> -->
      <img :src="List.img" />
      <!-- <div>
        <div
          v-for="(item, index3) in $i18n.locale == 'zh'
            ? List.title3
            : List.title3En"
          :key="index3"
          class="safe_p_div"
        >
          <p class="dot"></p>
          <p>{{ item }}</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import * as API from "@/api/index";
export default {
  data() {
    return {
      List: [],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  created() {},
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    //监听轮播数据变化后再创建实例
    List() {
      this.$nextTick(() => {
        // this.playMultipleBanner();
      });
    },
  },
  computed: {},
  mounted() {
    this.getList();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  destroyed() {},
  methods: {
    getList() {
      API.getHomeSafe()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.List = res.page.list[0];
            var title1 = this.List.title1.split("<br>");
            var title1En = this.List.title1En.split("<br>");
            var title2 = this.List.title2.split("<br>");
            var title2En = this.List.title2En.split("<br>");
            var title3 = this.List.title3.split("<br>");
            var title3En = this.List.title3En.split("<br>");
            console.log("title1==", title1);
            this.List.title1 = title1;
            console.log("title1==", title1);
            this.List.title1En = title1En;
            this.List.title2 = title2;
            this.List.title2En = title2En;
            this.List.title3 = title3;
            this.List.title3En = title3En;
          }
        })
        .catch(() => {});
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.safe_sub_div {
  padding: 0;
  background: linear-gradient(135deg, white, #00c8b7ff, $mainColor);
  // box-sizing: border-box;
  max-height: 108rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    text-align: start;
    font-size: 2.8rem;
    font-family: light;
    line-height: 4.5rem;
  }
  .p_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: max-content;
    margin-left: calc(50% - 2rem);
  }
  .safe_p_div {
    display: flex;
    align-items: center;
    .dot {
      width: 7px;
      height: 7px;
      background: white;
      border-radius: 50%;
      margin-right: 1rem;
    }
    &.reverse {
      flex-direction: row-reverse;
      .dot {
        margin-left: 1rem;
        margin-right: 0;
      }
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: right;
    }
    &.left {
      justify-content: left;
    }
  }
  .bottom_div {
    // margin-top: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      height: 80%;
      // max-height: 75.8rem;
      width: auto;
    }
  }
}
@media screen and (max-width: 1200px) {
  .safe_sub_div {
    .p_div {
      margin-left: 25vw;
    }
    .bottom_div {
      flex-direction: column;
      align-items: center;

      .safe_p_div {
        &.reverse {
          flex-direction: row;
          .dot {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
      img {
        width: 80%;
        // height: auto;
      }
    }
  }
}
</style>