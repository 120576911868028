<template>
  <div>
    <div
      class="top_div"
      :class="Detail.price ? 'zh' : ''"
      :style="{
        'background-image': `url(${Detail.img})`,
      }"
    >
      <div class="top_txt_div">
        <p class="label">{{ $t("莱富欧洲畅销品牌") }}</p>
        <p class="title">
          {{ $i18n.locale == "zh" ? Detail.title : Detail.titleEn }}
        </p>
        <p
          class="content"
          v-html="
            $i18n.locale == 'zh' ? Detail.littleTitle : Detail.littleTitleEn
          "
        ></p>
        <p class="price_title" v-if="Detail.price">
          {{ $i18n.locale == "zh" ? Detail.simple : Detail.simpleEn }}
        </p>
        <div class="price" v-if="Detail.price" @click="jumpTo(Detail.url)">
          <p>{{ Detail.price }}</p>
        </div>
      </div>
    </div>
    <safe-view></safe-view>
    <div class="detail_div">
      <div
        class="detail"
        :class="i % 2 != 0 ? 'reverse' : ''"
        v-for="(item, i) in Detail.content"
        :key="i"
      >
        <div class="left">
          <p class="num">0{{ i + 1 }}.</p>
          <p
            class="title"
            v-html="$i18n.locale == 'zh' ? item.subTitle : item.subTitleEn"
          ></p>
          <p
            class="content"
            v-html="$i18n.locale == 'zh' ? item.subContent : item.subContentEn"
          ></p>
        </div>
        <div class="right">
          <img :src="item.img" />
        </div>
      </div>
      <div class="parameter">
        <p class="title">{{ $t("参数") }}</p>
        <div>
          <el-row>
            <el-col
              :md="8"
              v-for="(item, i) in Detail.parameter"
              :key="i"
              class="out_item_div"
            >
              <div class="item_par">
                <div class="line_div">
                  <p class="p_title">
                    {{ $i18n.locale == "zh" ? item.subTitle : item.subTitleEn }}
                  </p>
                  <p class="p_content">
                    {{
                      $i18n.locale == "zh" ? item.subContent : item.subContentEn
                    }}
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <video controls v-if="Detail.video">
        <source :src="Detail.video"/>
      </video>
      <more-product-banner></more-product-banner>
    </div>
  </div>
</template>
  <script>
import * as API from "@/api/index";
import SafeView from "./view/SafeView.vue";
import MoreProductBanner from "./view/MoreProductBanner.vue";
export default {
  components: { SafeView, MoreProductBanner },
  data() {
    return {
      Detail: "",
      list: "",
      hasPrev: false,
      hasNext: false,
    };
  },
  watch: {
    $route(to, from) {
      //   console.log(to.path);
      if (to.path.indexOf("/productsdetail") != -1) {
        window.scrollTo(0, 0);
        this.getList();
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getList();
  },
  methods: {
    getList() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getProducts(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
            this.getDetail();
          }
        })
        .catch(() => {});
    },
    goNext() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (item.id == news_id && i + 1 < this.list.length) {
          next_id = this.list[i + 1].id;
          break;
        }
      }
      console.log("goNext");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    goPrve() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (i > 0 && item.id == news_id) {
          next_id = this.list[i - 1].id;
          break;
        }
      }
      console.log("goPrve");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    getDetail() {
      let news_id = window.location.href.split("id=")[1];
      API.getProductsDetail(news_id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.products;
            this.Detail.content = JSON.parse(res.products.content);
            this.Detail.parameter = JSON.parse(res.products.parameter);
          }

          for (var i = 0; i < this.list.length; i++) {
            var item = this.list[i];
            if (item.id == news_id) {
              if (i > 0) {
                this.hasPrev = true;
              } else {
                this.hasPrev = false;
              }
              if (i < this.list.length - 1) {
                this.hasNext = true;
              } else {
                this.hasNext = false;
              }
              break;
            }
          }
        })
        .catch(() => {});
    },

    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.top_div {
  height: 96rem;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 44.4rem 0 0 28rem;

  .top_txt_div {
    .label {
      width: fit-content;
      font-size: 2.4rem;
      color: $mainColor;
      border: 1px solid $mainColor;
      padding: 0.3rem 1.3rem;
    }
    .title {
      font-family: medium;
      font-size: 5rem;
      color: $mainColor;
      text-align: start;
      margin: 3rem 0;
    }
    .content {
      text-align: start;
      font-family: light;
      font-size: 2.8rem;
      color: $color4C;
      line-height: 4rem;
    }
    .price_title {
      margin-top: 8.5rem;
      font-size: 2.4rem;
      font-family: light;
      color: $color4C;
      text-align: start;
    }
    .price {
      display: flex;
      margin-top: 2rem;
      cursor: pointer;
      p {
        line-height: 1.5;
        padding: 0 1.7rem;
        width: fit-content;
        font-size: 3rem;
        font-family: light;
        color: white;
        text-align: center;
        background: linear-gradient(135deg, #00c8b7ff, $mainColor);
      }
      &::after {
        content: "";
        width: 4.3rem;
        height: 4.3rem;
        background: url("~@/../static/img/arrow_green_right.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto 0 auto 1.5rem;
      }
      &:hover {
        &::after {
          content: "";
          width: 4.3rem;
          height: 4.3rem;
          background: url("~@/../static/img/arrow_green_right2.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin: auto 0 auto 1.5rem;
        }
      }
    }
  }
  &.zh {
    padding: 35rem 0 0 28rem;
    .top_txt_div {
      .title {
        font-size: 8rem;
        margin: 2.3rem 0 0.6rem;
      }
      .content {
        font-size: 2.4rem;
        font-family: regular;
      }
    }
  }
}
.detail_div {
  width: 85.42%;
  max-width: 192rem;
  margin: auto;
  .detail {
    background: $colorF5;
    margin: 16rem 0 19rem;
    display: flex;
    height: 83.7rem;
    width: 100%;
    position: relative;
    &.reverse {
      flex-direction: row-reverse;
      .left {
        padding: 21.3rem 13.8rem 0 0;
        // position: absolute;
        right: 0;
        top: 0;
        left: unset;
        p {
          text-align: right;
        }
      }
    }
    .left {
      width: 40%;
      padding: 21.3rem 0 0 13.8rem;
      // position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      p {
        text-align: start;
      }
      .num {
        font-family: Outfit-SemiBold;
        font-size: 3rem;
        color: $mainColor;
      }
      .title {
        font-family: medium;
        font-size: 6rem;
        line-height: 8rem;
        color: $mainColor;
        margin: 4.6rem 0 7rem;
      }
      .content {
        font-family: light;
        font-size: 2.4rem;
        line-height: 4.2rem;
      }
    }
    .right {
      width: 60%;
      display: flex;
      justify-content: right;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        // object-position: right;
      }
    }
  }
  .parameter {
    margin-top: 20rem;
    .title {
      text-align: start;
      background: linear-gradient(135deg, #00c8b7ff, $mainColor);
      font-family: medium;
      font-size: 3.6rem;
      color: white;
      padding: 2rem 5.8rem;
      margin-bottom: 1rem;
    }
    .out_item_div {
      &:nth-child(3n + 2) {
        .line_div {
          border-left: 0.5px solid $colorBBB;
          border-right: 0.5px solid $colorBBB;
        }
      }
    }
    .item_par {
      padding: 3rem 0 1.7rem;
      border-bottom: 0.5px solid $colorBBB;

      .p_title {
        line-height: 3.2rem;
        font-family: light;
        font-size: 1.8rem;
        color: $color5A;
      }
      .p_content {
        line-height: 3.2rem;
        font-family: bold;
        font-size: 2rem;
        color: $color4D;
      }
    }
  }
}
video {
  width: 100%;
  margin-top: 20rem;
}

@media (max-width: 1500px) {
  .detail_div {
    .detail {
      margin: 6rem 0 9rem;
      // flex-direction: column;
      height: auto;
      .left {
        padding: 11.3rem 0 0 13.8rem;
        // width: 100%;
        box-sizing: border-box;
        // position: relative;
        .title {
          font-size: 4rem;
          line-height: 1.5;
          // margin: 2rem 0;
        }
        .content {
          font-size: 1.4rem;
          line-height: 1.5;
        }
      }
      .right {
        // width: 100%;
      }
      &.reverse {
        // flex-direction: column;
        .left {
          // position: relative;
          padding: 11.3rem 13.8rem 0 0;
          .title {
            font-size: 4rem;
            line-height: 1.5;
            // margin: 2rem 0;
          }
          p {
            text-align: right;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .top_div {
    padding: 24.4rem 0 0 4rem;
    &.zh {
      padding: 24.4rem 0 0 4rem;
      .top_txt_div {
        .title {
          font-size: 5rem;
          margin: 2.3rem 0 0.6rem;
        }
        .content {
          font-size: 2rem;
          font-family: regular;
        }
      }
    }
    .top_txt_div {
      .title {
        font-size: 4rem;
        margin: 3rem 0;
      }
      .content {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
  .detail_div {
    .detail {
      margin: 6rem 0 9rem;
      // flex-direction: column;
      height: auto;
      .left {
        padding: 4rem;
        width: 100%;
        box-sizing: border-box;
        // position: relative;
        .title {
          font-size: 2rem;
          line-height: 1.5;
          margin: 2rem 0;
        }
        .content {
          font-size: 1.4rem;
          line-height: 1.5;
        }
      }
      .right {
        width: 100%;
      }
      &.reverse {
        flex-direction: column;
        .left {
          // position: relative;
          padding: 4rem;
          .title {
            font-size: 2rem;
            line-height: 1.5;
            margin: 2rem 0;
          }
          p {
            text-align: right;
          }
        }
      }
    }
  }
  .parameter {
    .out_item_div {
      &:nth-child(3n + 2) {
        .line_div {
          border-left: unset !important;
          border-right: unset !important;
        }
      }
    }
  }
}
</style>