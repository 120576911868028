<template>
  <div class="banner_bg">
    <div class="top_title_div">
      <p>{{ $t("荣誉资质") }}</p>
      <div class="button_div">
        <div class="left" @click="swiperleft"></div>
        <div class="right" @click="swiperright"></div>
      </div>
    </div>
    <div class="swiper_bg">
      <div class="swiper-container mySwiper casesSwiper" id="casesSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in news" :key="index">
            <div
              class="cases_item_div bg_div"
              @click="navTo('/productsdetail/id=' + item.id)"
            >
              <img :src="item.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <p class="more_gray" @click="navTo('products')">{{ $t("了解更多") }}</p> -->
  </div>
</template>
<script>
import Swiper, { Autoplay } from "swiper";
// Swiper.use(Autoplay);
import "swiper/swiper-bundle.css";
import * as API from "@/api/index";
export default {
  props: ["pageSize", "listID"],
  data() {
    return {
      isMiniWindows: false,
      clientWidth: 0,
      activeIndex: -1,
      news: [],
      swiper: Object,
    };
  },
  created() {},
  watch: {
    $route(to, from) {
      if (to.path == "/about") {
        // this.getBannerList();
        // this.initSwiper();
        if(this.swiper){
          console.log('swiper====')
          this.swiper.slideToLoop(0,0);
          // this.swiper1.autoplay.start();
        }
      }
    },
    //监听轮播数据变化后再创建实例
    listID(newdata, olddata) {
      // console.log("watch newdata==", newdata);
      // console.log("watch olddata==", olddata);
      this.getCases();
    },
    news() {
      this.$nextTick(() => {
        this.initSwiper();
      });
    },
  },
  computed: {},
  mounted() {
    if (!this.listID) {
      this.getHonor();
    }
  },
  destroyed() {},
  methods: {
    getHonor() {
      this.news.length = 0;
      var data = {
        page: 1,
        limit: this.pageSize,
      };
      API.getHonor(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            if (this.listID) {
              for (var i = 0; i < this.listID.length; i++) {
                var id = this.listID[i];
                // console.log("i==", i);
                for (var j = 0; j < res.page.list.length; j++) {
                  // console.log("j==", j);
                  var item = res.page.list[j];
                  if (item.id == id) {
                    // console.log("push==", id);
                    this.news.push(item);
                    break;
                  }
                }
              }
            } else {
              this.news = res.page.list;
            }
          }
        })
        .catch(() => {});
    },
    initSwiper() {
      let that = this;
      this.$nextTick(() => {
        that.swiper = new Swiper(".casesSwiper", {
          slidesPerView: 1,
          centeredSlides: false,
          loop: true,
          spaceBetween: 10,
          observer:true,
          observeParents:true,
          autoplay: {
            disableOnInteraction: false,
            delay: 3000,
          },
          on: {
            activeIndexChange: function () {
              // console.log("改变了，activeIndex为" + this.activeIndex);
            },
            slideChange: function () {
              // console.log("slideChange==" + this.activeIndex);
              // console.log("slideChange real==" + this.realIndex);
              that.setActiveIndex(this.realIndex);
            },
          },
          breakpoints: {
            // 640: {
            //   slidesPerView: 1,
            //   spaceBetween: 30,
            // },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 38,
            },
          },
        });
      }, 600);
    },
    jumpTo(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    navTo(path) {
      this.$router.push(path);
    },
    //设置滑动切换轮播图
    swiperleft: function (index) {
      //上一页
      // this.$refs.carousel.prev();
      this.swiper.slidePrev();
      //设置幻灯片的索引
      // this.$refs.carousel.setActiveItem(index - 1);
    },
    swiperright: function (index) {
      //下一页
      // this.$refs.carousel.next();
      this.swiper.slideNext();
      // this.$refs.carousel.setActiveItem(index + 1);
    },
    setActiveIndex(nowindex, oldindex) {
      // console.log("now==", nowindex);
      // console.log("old==", oldindex);
      this.activeIndex = nowindex;
    },
  },
};
</script>
<style lang="scss" scoped>
.banner_bg {
  background: $colorF5-50;
  padding: 14rem 0;
  margin-bottom: 10rem;
}
.more_gray {
  margin: 7rem auto 16rem;
}
.top_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  width: 85.42%;
  margin: 0 auto 0;

  p {
    color: $mainColor;
    font-size: 5rem;
    width: fit-content;
  }
  .button_div {
    display: flex;
    .left {
      cursor: pointer;
      width: 2.6rem;
      height: 2.6rem;
      background: url("~@/../static/img/arrow_green_left.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        background: url("~@/../static/img/arrow_green_left2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .right {
      cursor: pointer;
      margin-left: 1.8rem;
      width: 2.6rem;
      height: 2.6rem;
      background: url("~@/../static/img/arrow_green_right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        background: url("~@/../static/img/arrow_green_right2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
.cases_item_div {
  cursor: pointer;
  margin-top: 10rem;
  height: 33rem;
  position: relative;
  // box-shadow: 5px 5px 5px $colorF5;
  // width: 67.4rem;
  // background: $colorF5;
  // box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .item_div {
    transition: all 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding-left: 4.7rem;
    padding-right: 5rem;
  }
  .p1 {
    margin-top: 6rem;
    text-align: start;
    color: $mainColor;
    font-size: 4rem;
    // line-height: 2.6rem;
    font-family: Outfit-Regular;
  }
  .p2 {
    text-align: start;
    color: $mainColor;
    // transition: all 0.5s;
    font-size: 3rem;
  }
  .p3 {
    text-align: start;
    color: $color4C;
    // transition: all 0.5s;
    font-size: 2rem;
    margin-bottom: 5rem;
    font-family: Outfit-Medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      content: "";
      width: 3rem;
      height: 3rem;
      background: url("~@/../static/img/arrow_green_gray_right.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  // &:hover {
  //   background: linear-gradient(135deg, white, #00c8b7ff, $mainColor);
  //   p {
  //     color: white;
  //   }
  //   .p3 {
  //     &::after {
  //       content: "";
  //       width: 3rem;
  //       height: 3rem;
  //       background: url("~@/../static/img/arrow_green_w_right.png");
  //       background-size: contain;
  //       background-position: center;
  //       background-repeat: no-repeat;
  //     }
  //   }
  // }
}
.banner-img {
  width: 100%;
  height: 100%;
  // aspect-ratio: 16/9;
  object-fit: cover;
  // border-radius: 2rem;
}
/deep/ .swiper-slide {
  // width: 80%;
  transform: translateX(14rem);
}
@media screen and (max-width: 1000px) {
  /deep/ .swiper-slide {
    // width: 80%;
    transform: translateX(0);
  }
}
</style>