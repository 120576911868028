<template>
  <div>
    <div
      class="top_img"
      :style="{
        'background-image': `url(${
          isMiniWindows ? imgs[0].phoneImg : imgs[0].img
        })`,
      }"
    >
      <p class="title">
        {{ $i18n.locale == "zh" ? imgs[0].name : imgs[0].nameEn }}
      </p>
      <div class="page_nav_div">
        <div class="total_margin_div flex end">
          <p
            :class="type == i + 1 ? 'active' : ''"
            v-for="(item, i) in nav_title[2].children"
            :key="i"
            @click="navItemClick(i)"
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="news_div">
      <div
        class="top_news_div"
        v-if="list[0]"
        @click="pushTo('/NewsDetail/id=' + list[0].id)"
      >
        <div class="img_div">
          <img :src="list[0].img" />
        </div>
        <div class="top_news_div_right">
          <p class="top_news_title double-line">
            {{ $i18n.locale == "zh" ? list[0].title : list[0].titleEn }}
          </p>
          <p class="top_news_content double-line">
            {{ $i18n.locale == "zh" ? list[0].simple : list[0].simpleEn }}
          </p>
          <p class="top_news_time">
            <span class="top_news_type">{{
              $i18n.locale == "zh" ? list[0].typeName : list[0].typeNameEn
            }}</span>
            {{ list[0].time }}
          </p>
        </div>
      </div>
      <el-row class="sub_news_div" :gutter="20">
        <el-col
          :md="8"
          v-for="(item, i) in list.slice(1, list.length)"
          :key="i"
        >
          <div
            class="news_sub_box"
            @click="pushTo('/NewsDetail/id=' + item.id)"
          >
            <div class="top_div">
              <div class="img_div">
                <img :src="item.img" />
              </div>
            </div>
            <div class="bottom_content">
              <p class="news_sub_title double-line">
                {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
              </p>
              <p class="news_sub_content double-line">
                {{ $i18n.locale == "zh" ? item.simple : item.simpleEn }}
              </p>
              <p class="news_sub_time">
                <span class="news_sub_type">{{
                  $i18n.locale == "zh" ? item.typeName : item.typeNameEn
                }}</span>
                {{ item.time }}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-pagination
        class="page_div"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="totalPage"
      >
      </el-pagination>
      <p class="more" @click="loadMore()">{{ $t("加载更多") }}</p>
    </div>
  </div>
</template>
  <script>
import * as API from "@/api/index";
import * as navBar from "./navbar/navbar";
export default {
  components: {},
  data() {
    return {
      list: [{ img: "" }],
      imgs: [{ img: "" }],
      currentPage: 1,
      totalPage: 1,
      pageSize: 10,
      type: 1, //新闻类型
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/cases") != -1) {
        var param = this.$route.query.param;
        if (param) {
          console.log("cases param:" + param);
          this.type = param;
        }
        this.getList();
        this.getImg();
        // if (from.path.indexOf("NewsDetail") == -1) {
        //   window.scrollTo(0, 0);
        // }
      }
    },
  },
  created() {},
  computed: {
    nav_title() {
      return navBar.getList();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getList();
    this.getImg();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getImg() {
      var p = { title: "新闻中心" };
      API.getImgs(p)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.list;
          }
        })
        .catch(() => {});
    },
    pushTo(path) {
      this.$router.push(path);
    },
    navItemClick(index) {
      this.type = index + 1;
      let url = this.changeURLParam(window.location.href, "param", this.type); // 修改 URL 参数
      console.log("url", url); //修改完之后的地址
      history.pushState("", "", url); // 不刷新页面
      this.currentPage = 1;
      this.getList();
    },
    changeURLParam(url, type, value) {
      let reg = eval("/([?|&]" + type + "=)[^&]*/gi");
      value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); // 移除首尾空格
      let url2;
      if (!value) {
        // remove
        url2 = url.replace(reg, "");
      } else {
        if (url.match(reg)) {
          // edit
          url2 = url.replace(reg, "$1" + value);
        } else {
          // add
          url2 = url + (url.indexOf("?") > -1 ? "&" : "?") + type + "=" + value;
        }
      }
      return url2;
    },
    loadMore() {
      this.currentPage++;
      var data = {
        type: this.type,
        page: this.currentPage,
        limit: this.pageSize,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            if (res.page.list.length > 0) {
              this.list = this.list.concat(res.page.list);
              this.currentPage = res.page.currPage;
              this.totalPage = res.page.totalPage;
            }
          }
        })
        .catch(() => {});
    },
    getList() {
      var data = {
        type: this.type,
        page: this.currentPage,
        limit: this.pageSize,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
            this.currentPage = res.page.currPage;
            this.totalPage = res.page.totalPage;
          }
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>
  <style lang="scss" scoped>
.total_margin_div {
  max-width: 144rem;
}
/deep/.el-pager li.active {
  color: $textRedColor;
}
/deep/.el-pager li:hover {
  color: $textRedColor;
}
.news_div {
  padding: 10rem 24rem;
  max-width: 144rem;
  margin-left: auto;
  margin-right: auto;
}
.top_news_div {
  margin-top: 7rem;
  background: $colorF5;
  display: flex;
  .img_div {
    width: 50%;
    overflow: hidden;
    // height: auto;
    max-height: 46rem;
  }
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: white;
    transition: all ease 0.4s;
  }
  .top_news_div_right {
    cursor: pointer;
    width: 50%;
    padding: 5.8rem 11.2rem 4rem 6.6rem;
    display: flex;
    flex-direction: column;

    .top_news_title {
      font-size: 3.2rem;
      color: $color2C;
      font-family: bold;
      line-height: 4.5rem;
      text-align: start;
    }
    .top_news_content {
      margin: 2.5rem 0 3.5rem;
      text-align: start;
      font-size: 1.6rem;
      font-family: normal;
      line-height: 2.8rem;
    }
    .top_news_type {
      text-align: start;
      font-size: 1.4rem;
      color: $textRedColor;
      background: $colorF6F6F7;
      padding: 2px 1.4rem;
    }
    .top_news_time {
      text-align: start;
      font-size: 1.4rem;
      margin-left: 1.3rem;
    }
    .news_more_touch_box {
      margin-top: auto;
      font-size: 1.8rem;
      border: $textGaryColor solid 1px;
      border-radius: 2rem;
      padding: 1rem 4rem;
      width: fit-content;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        transition: all 0.5s;
        border: $textBlueColor solid 1px;
        background-color: $textBlueColor;
        color: white;
      }
    }
  }
  &:hover {
    img {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
    .top_news_title {
      color: $textRedColor;
    }
  }
}
.sub_news_div {
  margin-top: 12rem;
}
.news_sub_box {
  cursor: pointer;
  background-color: transparent;
  transition: all 0.5s;
  .top_div {
    transition: all 0.5s;
    height: 32rem;
    background-color: $colorF5;
  }
  .img_div {
    overflow: hidden;
    width: 100%;
    height: 32rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
  }

  .bottom_content {
    margin: 1.8rem 0;
    display: flex;
    flex-direction: column;

    p {
      text-align: start;
    }

    .news_sub_title {
      font-size: 2rem;
      line-height: 3.2rem;
      font-family: bold;
      color: $color2C;
      margin: 1rem 0;
    }
    .news_sub_content {
      font-size: 1.6rem;
      margin-bottom: 3rem;
      line-height: 2.5rem;
    }
    .news_sub_type {
      font-size: 1.4rem;
      margin-right: 1.3rem;
      color: $textRedColor;
      background: $colorF6F6F7;
      padding: 2px 1.4rem;
    }
    .news_sub_time {
      font-size: 1.4rem;
    }
  }
  &:hover {
    transition: all 0.5s;
    img {
      transform: scale(1.1);
      transition: all ease 0.5s;
    }
    .bottom_content {
      .news_sub_title {
        color: $textRedColor;
      }
    }
  }
}
.page_div {
  display: block;
  margin: 10rem 0;
}
.more {
  display: none;
  position: relative;
  cursor: pointer;
  color: white;
  border: $textRedColor solid 1px;
  background: $textRedColor;
  font-size: 1rem;
  padding: 0.7rem 2.3rem;
  width: fit-content;
  transition: all 0.5s;
  box-sizing: border-box;
  margin: 7rem auto 10rem;
  // margin: 4.7rem auto 0;
  &::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background: url("../../static/img/news_arrow_down.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    // position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0 auto 0.5rem;
  }
  &:hover {
    transition: all 0.5s;
    color: white;
    border: $textRedColor solid 1px;
    background: $textRedColor;

    &::after {
      content: "";
      background: url("../../static/img/news_arrow_down.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
@media (max-width: 1200px) {
  .news_div {
    padding: 0 1.7rem;
  }
  .top_news_div {
    margin-top: 2rem;
    flex-direction: column;
    .img_div {
      width: 100%;
      height: 22.8rem;
    }
    .top_news_div_right {
      width: 100%;
      padding: 1rem 0;
      background: white;
      .top_news_title {
        font-size: 1.5rem;
        line-height: 1.9rem;
      }
      .top_news_content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin: 0.6rem 0 1.8rem;
      }
      .top_news_time {
        font-size: 1rem;
        margin-left: 0;
      }
      .top_news_type {
        font-size: 1rem;
      }
    }
  }
  .sub_news_div {
    margin-top: 0;
  }
  .news_sub_box {
    margin-top: 2rem;
    .top_div {
      height: 22.8rem;
    }

    .img_div {
      height: 22.8rem;
      img {
        height: 100%;
      }
    }
    .bottom_content {
      margin: 0;
      padding: 1rem 0;
      .news_sub_title {
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.9rem;
      }
      .news_sub_content {
        font-size: 1.1rem;
        line-height: 1.7rem;
        margin: 0.6rem 0 1.8rem;
      }
      .news_sub_time {
        font-size: 1rem;
      }
      .news_sub_type {
        font-size: 1rem;
        margin-right: 0;
      }
    }
  }
  .page_div {
    display: none;
  }
  .more {
    display: block;
  }
}
</style>