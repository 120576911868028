<template>
  <bm-overlay
    ref="customOverlay"
    :class="{ sample: true, active }"
    pane="labelPane"
    @draw="draw"
  >
    <div class="content">
      <div class="text" v-text="text" @click="handleClick"></div>
      <div class="arrow"></div>
    </div>
  </bm-overlay>
</template>
  
  <script>
export default {
  props: ["text", "position", "active"],
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload();
      },
      deep: true,
    },
  },
  methods: {
    handleClick() {
      // global.alert('Well done.')
    },
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 150 + "px";
      el.style.top = pixel.y - 120 + "px";
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.sample {
  width: 30rem;
  height: auto;
  position: absolute;
}
.content {
  position: relative;
}
.text {
  width: 20rem;
  // height: 10rem;
  //   line-height: 40px;
  background: white;
  overflow: hidden;
  box-shadow: 5px 5px 5px $colorE8;
  color: #fff;
  text-align: center;
  padding: 2rem;
  position: absolute;
  color: black;
  font-family: medium;
  font-size: 2rem;
}
.arrow {
  position: absolute;
  top: 8.8rem;
  left: 11rem;
  width: 0px;
  height: 0px;
  border-color: white transparent transparent;
  border-style: solid;
  border-width: 10px;
  overflow: hidden;
}
.sample.active {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}
</style>
  