<template>
  <div>
    <div
      class="top_img"
      :style="{
        'background-image': `url(${imgs[2].img})`,
      }"
    >
      <div class="top_title_div">
        <p class="en">Make it Safer</p>
        <p class="title">
          {{ $i18n.locale == "zh" ? imgs[2].title : imgs[2].titleEn }}
        </p>
      </div>
    </div>
    <div
      class="about_div"
      v-for="(item, i) in data"
      :key="i"
      :class="i % 2 != 0 ? 'reverse' : ''"
    >
      <div class="about_item_div">
        <p class="title">
          {{ $i18n.locale == "zh" ? item.title : item.titleEn }}
        </p>
        <p
          v-html="$i18n.locale == 'zh' ? item.content : item.contentEn"
          class="content"
        ></p>
      </div>
      <img :src="item.img" />
    </div>
    <honor-banner-vue></honor-banner-vue>
  </div>
</template>
<script>
import * as API from "@/api/index";
import HonorBannerVue from "./view/HonorBanner.vue";
export default {
  components: { HonorBannerVue },
  data() {
    return {
      data: {},
      imgs: [{ img: "" }],
      isMiniWindows: false,
      clientWidth: 0,
    };
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1200) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
    },
    $route(to, from) {
      if (to.path.indexOf("/about") != -1) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getImg();
    this.getData();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },
  methods: {
    getImg() {
      // var p = { title: "莱富 安全第一" };
      API.getImgs()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.imgs = res.page.list;
          }
        })
        .catch(() => {});
    },

    getData() {
      API.getAbout()
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.data = res.page.list;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.about_div {
  display: flex;
  width: 85.42%;
  max-width: 192rem;
  margin: 18rem auto 20rem;
  &.reverse {
    flex-direction: row-reverse;
    margin: 18rem auto 22rem;
    .about_item_div {
      .content {
        padding: 7.5rem 0 0 13.8rem;
      }
      .title {
        padding-left: 13.8rem;
      }
    }
  }
  .about_item_div {
    width: 50%;
    .title {
      font-size: 5rem;
      color: $mainColor;
      text-align: start;
    }
    .content {
      text-align: start;
      line-height: 3.4rem;
      padding: 7.5rem 13.8rem 0 0;
    }
  }
  img {
    height: 55rem;
    width: 50%;
    object-fit: cover;
  }
}
@media (max-width: 1200px) {
  .about_div {
    flex-direction: column;
    &.reverse {
      flex-direction: column;
      .about_item_div {
        .content {
          padding: 4rem 0;
        }
        .title {
          padding-left: 0;
        }
      }
    }
    .about_item_div {
      width: 100%;
      .content {
        padding: 4rem 0;
      }
    }
    img {
      width: 100%;
    }
  }
}
</style>