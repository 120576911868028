import enLocale from 'element-ui/lib/locale/lang/en'
const msg = {
    ...enLocale,
    test: 'English',
    首页: 'Home',
    关于我们: 'About',
    产品中心: 'Industrial layout',
    新闻资讯: 'News center',
    联系我们: 'Contact us',
    企业简介: 'Introduction of Hecai',
    核心优势: 'Main Advantages',
    生产实力: 'Production Devices',
    荣誉资质: 'Honor & Reputation',
    产品证书: 'Certificates',
    企业新闻: 'Company News',
    行业动态: 'Industry news',
    公司地址: 'Location',
    联系方式: 'Contacts',
    产品系列: 'Products',
    更多: 'More',
    详细内容: 'Details',
    了解详情: 'Details',
    了解更多: 'Learn more',
    查看详情: 'Details',
    详情: 'Details',
    占地面积: 'Factory Area',
    年: '/',
    公司成立于: 'Founded in',
    名员工: 'Employees',
    更多新闻: 'More News',
    出口国家: 'Export to Countries',
    产品细节: 'Product details',
    产品参数: 'Specifications',
    产品视频: 'Product videos',
    文档说明书: 'Documentation',
    地址: 'Address',
    电话: 'International Dept',
    内销部: 'Domestic Sales Dept',
    传真: 'FAX',
    邮箱: 'E-mail',
    网址: 'Website',
    返回: 'Return',
    上一条: 'Prev',
    下一条: 'Next',
    点击下载: 'Download',
    下载: 'Download',
    服务热线: '24小时服务热线',
    查看全部: 'All',
    版权所有: '版权所有@宁波市海曙莱富医疗科技有限公司',
    观看产品视频: 'Video',
    搜索: 'Search',
    搜索产品: 'Search Products',
    装箱清单: 'Packing list',
    产业布局: 'Industrial layout',
    新闻及活动: 'News and events',
    集团概况: 'Group profile',
    组织架构: 'Organizational structure',
    企业文化: 'Enterprise culture',
    发展历程: 'Development course',
    公司: 'Zhejiang Henghe Industrial Group Co., Ltd.',
    上一篇: 'Prev',
    下一篇: 'Next',
    化工版块: 'Chemical sector',
    投资版块: 'Investment sector',
    置业版块: 'Property sector',
    跳转官网: 'Skip to official website',
    投资简介: 'Brief introduction to investment',
    投资项目: 'Investment project',
    月: '',
    恒河成员公司: 'Henghe Member Companies ',
    恒河集团架构: 'Henghe Group Structure',
    集团新闻: 'News of group',
    政策法规: 'Policies and regulations',
    关闭:'Close',
    子公司网站:'Websites of subsidiaries',
    股权投资:'Equity investment',
    实业投资:'Industrial investment',
    加载更多:'Load More',
    莱富安全第一:'莱富 安全第一',
    首页导航:'首页导航',
    所有产品:'所有产品',
    参数:'参数',
    莱富欧洲畅销品牌:'莱富 欧洲畅销品牌',
}
export default msg;