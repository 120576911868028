import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home'
import Index from '@/components/Index'
import About from '@/components/About'
import Cases from '@/components/Cases'
import News from '@/components/News'
import Products from '@/components/Products'
import ProductsDetail from '@/components/ProductsDetail'
import NewsDetail from '@/components/NewsDetail'
import CallUs from '@/components/CallUs'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '有限公司',
    component: Index,
    children: [{
      path: '/',
      component: Home,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },

    },
    {
      path: '/products',
      component: Products,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/about',
      component: About,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/productsdetail/:id',
      component: ProductsDetail,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    {
      path: '/contact',
      component: CallUs,
      name: '',
      meta: { title: '有限公司', titleEn: 'Zhejiang ' },
    },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
