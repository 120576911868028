<template>
  <div>
    <div class="detail_div ">
      <p class="detail_title">
        {{ $i18n.locale == "zh" ? Detail.title : Detail.titleEn }}
      </p>
      <p class="detail_time">
        <span class="detail_type">{{
          $i18n.locale == "zh" ? Detail.typeName : Detail.typeNameEn
        }}</span>
        {{ Detail.time }}
      </p>
      <p
        class="detail_content"
        v-html="$i18n.locale == 'zh' ? Detail.content : Detail.contentEn"
      ></p>
    </div>
    <div class="bottom_div">
      <p class="news_detail_more_touch_box_left" @click="goPrve" v-if="hasPrev">
        {{ $t("上一篇") }}
      </p>
      <p class="news_detail_more_touch_box" @click="goback">
        {{ $t("返回") }}
      </p>

      <p
        class="news_detail_more_touch_box_right"
        @click="goNext"
        v-if="hasNext"
      >
        {{ $t("下一篇") }}
      </p>
    </div>
  </div>
</template>
  <script>
import * as API from "@/api/index";
export default {
  components: {},
  data() {
    return {
      Detail: "",
      list: "",
      hasPrev: false,
      hasNext: false,
    };
  },
  watch: {
    $route(to, from) {
      //   console.log(to.path);
      if (to.path.indexOf("/newsdetail") != -1) {
        window.scrollTo(0, 0);
        this.getList();
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  created() {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
    this.getList();
  },
  methods: {
    getList() {
      var data = {
        page: 1,
        limit: 999,
      };
      API.getNews(data)
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.list = res.page.list;
            this.getDetail();
          }
        })
        .catch(() => {});
    },
    goNext() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (item.id == news_id && i + 1 < this.list.length) {
          next_id = this.list[i + 1].id;
          break;
        }
      }
      console.log("goNext");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    goPrve() {
      let news_id = window.location.href.split("id=")[1];
      var next_id = "";
      for (var i = 0; i < this.list.length; i++) {
        var item = this.list[i];
        if (i > 0 && item.id == news_id) {
          next_id = this.list[i - 1].id;
          break;
        }
      }
      console.log("goPrve");
      if (next_id != "") this.$router.push("/NewsDetail/id=" + next_id);
    },
    getDetail() {
      let news_id = window.location.href.split("id=")[1];
      API.getNewsDetail(news_id)
        .then((res) => {
          console.log(res);
          var status = res.code;
          if (status === 0) {
            this.Detail = res.news;
          }

          for (var i = 0; i < this.list.length; i++) {
            var item = this.list[i];
            if (item.id == news_id) {
              if (i > 0) {
                this.hasPrev = true;
              } else {
                this.hasPrev = false;
              }
              if (i < this.list.length - 1) {
                this.hasNext = true;
              } else {
                this.hasNext = false;
              }
              break;
            }
          }
        })
        .catch(() => {});
    },
    goback() {
      // this.$router.back();
      this.$router.push("/News");
    },
  },
};
</script>
  <style lang="scss" scoped>
.detail_div {
  // padding:20rem 45rem;
  margin: 20rem auto;
  max-width: 116rem;
  .detail_title {
    font-family: bold;
    font-size: 4.5rem;
    color: $color2C;
    line-height: 7rem;
  }
  .detail_type {
    font-size: 1.8rem;
    color: $textRedColor;
    margin: 6rem 0;
    background: $colorF6F6F7;
    padding: 2px 1.4rem;
    margin-right: 1.3rem;
  }
  .detail_time {
    font-size: 1.8rem;
    color: $color87;
    margin: 6rem 0;
  }
  /deep/ .detail_content {
    font-family: regular;
    font-size: 2.2rem;
    line-height: 4rem;
    p {
      font-family: regular;
      font-size: 2.2rem;
      line-height: 4rem;
    }
    img {
      max-width: 100%;
    }
  }
}
.bottom_div {
  width: 90%;
  margin: 20rem auto 26rem;
  display: flex;
  position: relative;
  justify-content: center;
  p:nth-child(2) {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .right_div {
    position: absolute;
    right: 0;
    display: flex;
    p:nth-child(2) {
      margin-left: 5rem;
    }
  }
}
.news_detail_more_touch_box {
  font-size: 2.2rem;
  border: $color9C solid 1px;
  // border-radius: 5rem;
  padding: 1rem 4rem;
  width: fit-content;
  min-width: 14.5rem;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
  box-sizing: border-box;
  color: $color9C;

  &:hover {
    transition: all 0.5s;
    border: $textRedColor solid 1px;
    background-color: $textRedColor;
    color: white;
  }
}

.news_detail_more_touch_box_left {
  @extend.news_detail_more_touch_box;
  padding: 1rem 4rem 1rem 5rem;
  position: relative;
  &::before {
    content: "";
    width: 2rem;
    height: 1.8rem;
    background: url("../../static/img/news_icon_left.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    // position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0.5rem auto 0;
    // left: 15%;
  }
  &:hover {
    &::before {
      background: url("../../static/img/news_icon_left_w.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
.news_detail_more_touch_box_right {
  @extend.news_detail_more_touch_box;
  padding: 1rem 5rem 1rem 4rem;
  position: relative;
  &::after {
    content: "";
    width: 2rem;
    height: 1.8rem;
    background: url("../../static/img/news_icon_right.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    top: 0;
    bottom: 0;
    margin: auto 0 auto 0.5rem;
    // right: 15%;
  }
  &:hover {
    &::after {
      background: url("../../static/img/news_icon_right_w.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 1200px) {
  .detail_div {
    margin: 4.5rem 1.7rem;
    .detail_title {
      font-size: 2.3rem;
      line-height: 3.2rem;
    }
    .detail_type {
      font-size: 1.2rem;
    }
    .detail_time {
      font-size: 1.2rem;
      margin: 2.8rem 0 4rem;
    }
    /deep/ .detail_content {
      font-size: 1.3rem;
      line-height: 2.2rem;
      p {
        font-size: 1.3rem;
        line-height: 2.2rem;
      }
    }
  }
  .bottom_div {
    margin: 8rem auto 10rem;
    width: 100%;
    justify-content: space-evenly;
    .right_div {
      display: none;
    }
    p {
      &:nth-child(2) {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
    }
  }
  .news_detail_more_touch_box {
    min-width: unset;
    font-size: 1.3rem;
    padding: 0.6rem 3rem;
  }
  .news_detail_more_touch_box_left {
    &::before {
      width: 1rem;
      height: 1rem;
      margin: auto 0.1rem auto 0;
    }
  }
  .news_detail_more_touch_box_right {
    &::after {
      width: 1rem;
      height: 1rem;
      margin: auto 0 auto 0.1rem;
    }
  }
}
</style>