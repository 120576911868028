<template>
  <div>
    <div
      :class="[
        !isMiniWindows ? (active_nav != 0 ? '' : '') : '',
        showWhiteNav ? 'isHover' : '',
        !isMiniWindows && isHide ? 'isHide' : '',
      ]"
      class="nav_father"
      id="nav_father"
      @mouseenter="navEnter()"
      @mouseleave="navLeave()"
    >
      <!-- <div class="nav_search">
        <el-autocomplete
          size="medium"
          :placeholder="$t('搜索')"
          suffix-icon="el-icon-search"
          clearable
          v-model="input"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="searchAll"
          @clear="hideSearchDiv()"
          @blur="hideSearchDiv()"
          v-show="showSearch"
        >
        </el-autocomplete>
        <img
          :src="require('../../../static/img/search_icon.png')"
          @click="showSearchDiv()"
          v-show="!showSearch"
        />
        <img
          :src="
            language == 'zh'
              ? require('../../../static/img/language_en.png')
              : require('../../../static/img/language_cn.png')
          "
          @click="selectlang"
        />
      </div> -->
      <div class="my_nav" :class="[pcBarOpenState ? 'pcBarOpen' : '']">
        <div class="nav_logo_div" @mouseenter="navLeave()">
          <img
            @click="navto('/')"
            :src="
              !isMiniWindows
                ? require('../../../static/img/logo.png')
                : navEnterState || showWhiteNav
                ? require('../../../static/img/logo.png')
                : require('../../../static/img/logo.png')
            "
          />
          <!-- <img
            @click="navto('/')"
            :src="require('../../../static/img/logo.png')"
          /> -->
          <!-- <p>{{ imgs[30].url }}</p> -->
        </div>
        <div class="ul_div">
          <ul
            id="ul_header"
            @mouseleave="navMenuLeave()"
            :class="$i18n.locale != 'zh' ? 'en' : ''"
          >
            <li
              :class="[
                active_nav == i ? 'active' : '',
                pcBarOpenState ? 'en' : '',
              ]"
              class="nav_home"
              v-for="(item, i) in nav_title_list"
              :key="item.title"
              @mouseenter="navMenuEnter(i)"
              @click="navto(item.path)"
            >
              <a :id="'ul_header' + i">{{ item.title }}</a>
            </li>
          </ul>
          <!-- <div class="icon-li">
            <p>
              <span @click="pcBarOpen()" v-if="pcBarOpenState">{{
                $t("关闭")
              }}</span>
              <span @click="selectlang('zh')" v-if="!pcBarOpenState">中</span>
              <span @click="selectlang('en')" v-if="!pcBarOpenState"> | EN</span>
            </p>
            <img
              @click="!isMiniWindows ? pcBarOpen() : openSider()"
              :src="pcBarOpenState || drawer ? barCloseImg : barMenuImg"
            />
          </div> -->
        </div>
        <!-- <div class="sider-img-div">
          <img
            class="sider-img2"
            :src="
              navEnterState || showWhiteNav
                ? language == 'zh'
                  ? require('../../../static/img/language_en.png')
                  : require('../../../static/img/language_cn.png')
                : language == 'zh'
                ? require('../../../static/img/language_en_w.png')
                : require('../../../static/img/language_cn_w.png')
            "
            @click="selectlang"
          />
          <img
            class="sider-img"
            :src="
              drawer
                ? sider_close_img
                : navEnterState || showWhiteNav
                ? sider_open_img2
                : sider_open_img
            "
            @click="openSider"
          />
        </div> -->
      </div>
    </div>

    <el-drawer
      class="nav-sider"
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      size="100%"
      :direction="direction"
    >
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <!-- 递归动态菜单 -->
        <myitem :data="nav_title_list"></myitem>
      </el-menu>
      <!-- <div class="drawer_search">
        <el-autocomplete
          :placeholder="$t('搜索')"
          suffix-icon="el-icon-search"
          clearable
          v-model="input"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="searchAll"
          @clear="hideSearchDiv()"
          @blur="hideSearchDiv()"
        >
        </el-autocomplete>
      </div> -->
    </el-drawer>
  </div>
</template>

<script>
import * as API from "@/api/index";
import sider_open_img from "../../../static/img/nav_open.png";
import sider_open_img2 from "../../../static/img/nav_open2.png";
import sider_close_img from "../../../static/img/nav_close.png";
import barMenuImg from "../../../static/img/change_icon.png";
import barCloseImg from "../../../static/img/change_icon_close.png";
import * as navBar from "./navbar";
import myitem from "./myitem.vue";
export default {
  data() {
    return {
      drawer: false,
      sider_close_img: sider_close_img,
      sider_open_img: sider_open_img,
      sider_open_img2: sider_open_img2,
      active_nav: 0,
      productSelectIndex: -1,
      aboutSelectIndex: -1,
      navMenuIndex: -1,
      navEnterState: false,
      showWhiteNav: false,
      clientWidth: 0,
      isMiniWindows: false,
      product_list: [],
      imgs: [],
      oldScrollTop: 0,
      isHide: false,
      language: "zh",
      input: "",
      showSearch: false,
      pcBarOpenState: false,
      barMenuImg: barMenuImg,
      barCloseImg: barCloseImg,
      direction: "ttb", //ltr从左往右开;rtl从右往左开;ttb从上往下开;btt从下往上开
    };
  },
  computed: {
    nav_title_list() {
      return navBar.getList();
    },
  },
  components: {
    myitem,
  },
  watch: {
    clientWidth(newVal, oldVal) {
      // console.log(newVal); //浏览器窗口变化时，打印宽度。
      if (newVal < 1000) {
        this.isMiniWindows = true;
      } else {
        this.isMiniWindows = false;
      }
      this.setProductMenuLeft();
    },
    $route(to, from) {
      console.log("nav bar to.path==" + to.path);
      this.drawer = false;
      if (to.path == "/Search") {
        for (var i = 0; i < this.nav_title_list.length; i++) {
          var item = this.nav_title_list[i];
          if (from.path.indexOf(item.path) != -1) {
            console.log("from.path==" + item.title);
            this.active_nav = i;
          }
        }
      } else {
        for (var i = 0; i < this.nav_title_list.length; i++) {
          var item = this.nav_title_list[i];
          if (to.path == item.path) {
            console.log("nav bar to.path==" + item.title);
            this.active_nav = i;
            break;
          } else {
            this.active_nav = -1;
          }
        }
      }
      //   var param = this.$route.query.param;
      //   this.path = param;
      //   console.log("param:" + param);
      //   this.navtoPartPath(this.path);
    },
  },
  mounted() {
    this.Init();
    let that = this;
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    that.clientWidth = clientWidth;
    window.onresize = () => {
      return (() => {
        var clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth;
        that.clientWidth = clientWidth;
      })();
    };
  },

  methods: {
    Init() {
      // 监听页面滚动事件
      window.addEventListener("scroll", this.scrolling);

      // this.getTypeList();
      let path = window.location.pathname;

      // console.log("navbar=="+window.location.pathname)
      for (var i = 0; i < this.nav_title_list.length; i++) {
        var item = this.nav_title_list[i];
        if (path.indexOf(item.path) != -1) {
          console.log("to.path==" + item.title);
          this.active_nav = i;
        } else if (path == "/Search") {
        }
      }
    },
    navEnter() {
      //当是pc端时生效
      if (!this.isMiniWindows) {
        this.navEnterState = true;
      }
    },
    navLeave() {
      this.navEnterState = false;
      this.navMenuIndex = -1;
    },
    navMenuEnter(index) {
      console.log("navMenuIndex==" + index);
      var menu = document.getElementById("about_menu");
      if (menu) {
        menu.style.opacity = 0;
      }
      setTimeout(() => {
        var obj = document.getElementById("ul_header" + index);
        var obj_self = obj.clientWidth;
        var menu = document.getElementById("about_menu");
        //获取顶部ul_header距离左边边界的距离
        var left = this.getLeftSide("ul_header" + index) + obj_self / 2;
        //设置product_div的margin-left,使其对齐
        if (menu) {
          var self_width = menu.clientWidth;
          // console.log("self_width", self_width);
          menu.style.marginLeft = left - self_width / 2 + "px";
          // console.log("menu left", menu.offsetLeft);
          menu.style.opacity = 1;
        }
      }, 100);
      this.navMenuIndex = index;
    },
    navMenuLeave() {
      if (this.navMenuIndex == 3) {
        this.navEnterState = false;
        this.navMenuIndex = -1;
      }
    },
    aboutEnter(index) {
      console.log("aboutSelectIndex==" + index);
      this.aboutSelectIndex = index;
    },
    aboutLeave() {
      this.aboutSelectIndex = -1;
    },
    productEnter(index) {
      console.log("productEnter==" + index);
      this.productSelectIndex = index;
    },
    productLeave() {
      this.productSelectIndex = -1;
    },
    pcBarOpen() {
      this.pcBarOpenState = !this.pcBarOpenState;
      this.setProductMenuLeft();
    },
    setProductMenuLeft() {
      if (this.pcBarOpenState) {
        setTimeout(() => {
          //获取顶部ul_header距离左边边界的距离
          var left = this.getLeftSide("ul_header");
          //设置product_div的margin-left,使其对齐
          var menu = document.getElementById("product_menu");
          console.log("menu left", menu.offsetLeft);
          menu.style.marginLeft = left + "px";
          menu.style.opacity = 1;
        }, 200);
      }
    },
    getTypeList() {
      API.getProductType()
        .then((res) => {
          var status = res.code;
          if (status === 0) {
            this.product_list = res.data;
            this.nav_title_list[2].children = this.product_list;
          }
        })
        .catch(() => {});
    },
    openSider() {
      this.drawer = !this.drawer;
      this.navEnterState = this.drawer;
      this.showWhiteNav = this.drawer;
    },
    navto(path) {
      if (this.isWechat()) {
        this.$router.replace(path);
      } else {
        this.$router.push(path);
      }
    },
    scrollNavto(path, name) {
      this.pcBarOpenState = false;
      this.navMenuIndex = -1;
      // this.isHide = true;
      this.$router.push({
        path: `${path}`,
        query: { param: name },
      });
    },
    isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },
    // 头部导航做鼠标滚动优化
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      // console.log("oldScrollTop==" + this.oldScrollTop);
      // console.log("scrollStep==" + scrollStep);
      if (!this.drawer && this.oldScrollTop < 200) {
        this.isHide = false;
        if (!this.drawer && scrollStep > 0) {
          this.showWhiteNav = true;
        } else {
          this.showWhiteNav = false;
        }
      } else {
        if (!this.drawer && scrollStep > 0) {
          this.isHide = true;
        } else {
          this.isHide = false;
          this.showWhiteNav = true;
        }
      }
    },
    selectlang(lang) {
      // if (this.language == "zh") {
      //   this.language = "en";
      // } else {
      //   this.language = "zh";
      // }
      this.language = lang;
      console.log("selectLanguage==", this.language);
      // 根据监听改变获取到的值去数组里找到对应的值
      localStorage.setItem("lang", this.language);
      this.$i18n.locale = this.language; //切换字典文件
      document.title =
        this.language == "zh"
          ? this.$route.meta.title
          : this.$route.meta.titleEn;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showSearchDiv() {
      this.showSearch = true;
    },
    hideSearchDiv() {
      console.log("hideSearchDiv");
      if (!this.input) {
        this.showSearch = false;
      }
    },
    searchAll(item) {
      console.log("searchAll==");
      if (item.type == 1) {
        //产品
        this.navto("/ProductsDetail/id=" + item.id);
      } else {
        this.navto("/NewsDetail/id=" + item.id);
      }
    },
    querySearch(queryString, cb) {
      if (queryString) {
        console.log("querySearch==", queryString);
        var types = [];
        var data = {
          key: this.input,
          page: 1,
          limit: 999,
        };
        API.getAllList(data)
          .then((res) => {
            var status = res.code;
            if (status === 0) {
              types = res.data.list;
              cb(types);
            }
          })
          .catch(() => {});
      }
    },
    //获取距离浏览器左边的距离
    getLeftSide(obj) {
      var su = document.getElementById(obj);
      var leftOffset = su.offsetLeft;
      console.log(obj + ":left offset", leftOffset);
      return leftOffset;
    },
  },
  created() {
    // 获取缓存的语种，如若没有（新用户），保存当前默认语言
    if (localStorage.getItem("lang")) {
      console.log(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", this.language);
    }
    this.language = localStorage.getItem("lang");
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.scrolling);
  },
  updated() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.drawer_search {
  width: 100%;
  padding: 5rem 3rem;

  .el-autocomplete {
    width: 100%;
  }
}
</style>
